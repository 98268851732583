// @flow
import {
  GET_EVENTS_FOR_PROJECT_REQUEST,
  GET_EVENTS_FOR_PROJECT_SUCCESS,
  EVENT_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,

  eventsList: [],
  eventsLimit: 20,
  eventsOffset: 0,
};

const Event = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_EVENTS_FOR_PROJECT_SUCCESS:
      let evnts = [];
      if (Array.isArray(action.payload.eventsList) && action.payload.eventsList.length > 0) {
        evnts = action.payload.eventsList;
      }

      return {
        ...state,
        loading: false,
        eventsList: evnts,
        eventsLimit: action.payload.eventsLimit,
        eventsOffset: action.payload.eventsOffset,
      }

    case EVENT_API_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default Event;
