export const formatTNLocation = (tn) => {
  if (!tn) {
    return '';
  }
  
  let locArr = [tn?.locationName, tn?.regionName, tn?.countryCode];
  return locArr.filter(l => l /* filtering non-empty values only */).join(', ');
}

export const formatTNType = (tn) => {
  if (!tn) {
    return '';
  }

  if (tn.type === 'local') {
    return 'local';
  }
  
  if (tn.type === 'toll-free') {
    return 'toll-free';
  }

  if (tn.type === 'short') {
    return 'short code';
  }

  return '';
}