import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {

  const user = useSelector((state) => state.AuthUser.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && user===undefined) { //this will show an empty screen while we are loading initial data from firebase
          return (
            <div/>
          );
        }

        if (isAuthProtected && user===null) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  authUser: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(Authmiddleware);
