// @flow
import {
  GET_AUTH_USER_PROJECTS_REQUEST,
  GET_AUTH_USER_PROJECTS_SUCCESS,
  AUTH_USER_CHANGED,
  CURRENT_PROJECT_ID_CHANGED,
  GET_AUTH_USERS_FOR_PROJECT_REQUEST,
  GET_AUTH_USERS_FOR_PROJECT_SUCCESS,
  GET_INVITE_DETAILS_REQUEST,
  GET_INVITE_DETAILS_SUCCESS,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_SUCCESS,
  DELETE_USER_FROM_PROJECT_REQUEST,
  DELETE_USER_FROM_PROJECT_SUCCESS,
  AUTH_USER_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,
  user: undefined,
  currentProjectId: '',
  projectsList: undefined,
  projectUsersList: undefined,
  inviteDetails: undefined,
};

const AuthUser = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUTH_USER_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_AUTH_USER_PROJECTS_SUCCESS:
      let projects = state.projectsList; // this will keep old values in case the result is not an array

      // updating currentProjectId if it was not selected before (and if we have an array of projects)
      let currentProjectId = state.currentProjectId ?? '';
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        projects = action.payload;
        if (!currentProjectId || !projects.some(e => e.projectId === currentProjectId)) { // if we haven't selected a project yet or a selected project does not exist anymore (was deleted from the db)
          currentProjectId = projects[0].projectId;
        }
      } else {
        currentProjectId = '';
      }

      return {
        ...state,
        loading: false,
        projectsList: projects,
        currentProjectId: currentProjectId
      }

    case GET_AUTH_USERS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_AUTH_USERS_FOR_PROJECT_SUCCESS:
      let users = state.projectUsersList; // this will keep old values in case the result is not an array

      if (Array.isArray(action.payload) && action.payload.length > 0) {
        users = action.payload;
      }

      return {
        ...state,
        loading: false,
        projectUsersList: users
      }

    case AUTH_USER_CHANGED:
      return { ...state, user: action.payload };

    case CURRENT_PROJECT_ID_CHANGED:
      return { ...state, currentProjectId: action.payload };

    case GET_INVITE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteDetails: action.payload,
      }

    case ACCEPT_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case ACCEPT_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteDetails: undefined,
      };

    case AUTH_USER_API_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_USER_FROM_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case DELETE_USER_FROM_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default AuthUser;
