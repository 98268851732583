const API_URL = process?.env?.REACT_APP_API_URL || 'http://localhost:20001/dash';

module.exports = {
  API_URL: API_URL,
  AUTH_TOKEN_EXPIRATION_HOURS: process?.env?.REACT_APP_AUTH_TOKEN_EXPIRATION_HOURS || 3,
  google: {
    API_KEY: "",
    CLIENT_ID: "789916937707-qs590a3uq2klda8k4ifhmp5aechdp060.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
}