import { get } from "../helpers/api_helper";

class EventService {

  fetchEvents(projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/events?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

}

export const eventService = new EventService();