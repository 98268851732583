import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table, Alert, Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Redirect, withRouter, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getPaymentMethodsForProject,
  deletePaymentMethod,
  stripeApiError
} from "../../store/actions";

import { constructPaymentMethodBrandLogo } from "./common";

import '../datatables.scss';

const PaymentMethodsTable = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const paymentMethodsList = useSelector((state) => state.Billing.paymentMethodsList);
  const paymentMethodsLoading = useSelector((state) => state.Billing.loading);

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message when we added a payment method
  const [redirectToAddPaymentMethod, setRedirectToAddPaymentMethod] = useState(false); // a flag to redirect to AddPaymentMethod form

  // Retrieve the "setup_intent_client_secret" query parameter appended to
  // your return_url by Stripe.js
  const redirectStatus = new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {

    if (redirectStatus === 'succeeded') {
      setSuccessMessage("Payment method was successfully added to the project");
    }

    if (redirectStatus === 'delete-payment-method-succeeded') {
      setSuccessMessage("Payment method was successfully deleted");
    }
  }, [redirectStatus]);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getPaymentMethodsForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId, redirectStatus]);

  const onAddPaymentMethodClick = () => {
    dispatch(stripeApiError('')); // clear the error message first
    setRedirectToAddPaymentMethod(true); // show the add payment method form
  }

  const onDeletePaymentMethodClick = (paymentMethodId) => {
    if (window.confirm("Are you sure you want to delete this payment method?") === true) {
      dispatch(deletePaymentMethod(currentProjectId, paymentMethodId, () => history.push(`/projects/${currentProjectId}/billing/payment-methods?redirect_status=delete-payment-method-succeeded`)))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Billing" breadcrumbItem="Payment Methods" />
          <Card>
            <CardBody>
              {paymentMethodsLoading && <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              }
              {redirectToAddPaymentMethod && <Redirect push to={`/projects/${currentProjectId}/billing/payment-methods/add`} />}
              {!paymentMethodsLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
              {!paymentMethodsLoading &&
                <div className="actions clearfix">
                  <ul>
                    <li
                    >
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md"
                        onClick={onAddPaymentMethodClick}
                      >
                        Add Payment Method
                      </Button>
                    </li>
                  </ul>
                </div>
              }
              {!paymentMethodsLoading &&
                <>
                  <Row>
                    <Col className="col-12">
                      <Table className="table table-striped table-responsive">
                        <tbody>
                          {(!paymentMethodsList || !Array.isArray(paymentMethodsList) || paymentMethodsList.length === 0) && <tr><td colSpan={3} className="text-center">No payment methods yet</td></tr>}
                          {
                            paymentMethodsList && Array.isArray(paymentMethodsList) && paymentMethodsList.length > 0 && paymentMethodsList.map((h, i) => <tr key={i} className="align-middle">
                              <td style={{ width: "10%" }}><i className="uil-trash-alt" title="Remove this token" role="button" onClick={() => onDeletePaymentMethodClick(h?.paymentMethodId)}></i></td>
                              <td style={{ width: "90%" }}>
                                <div>{constructPaymentMethodBrandLogo(h?.card?.brand)}<strong>
                                  &nbsp;**** **** **** {h?.card?.last4}</strong></div>
                                <div><i>expires {h?.card?.expMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 })}/{h?.card?.expYear}</i></div>
                              </td>
                            </tr>)
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </Container>
      </div >
    </React.Fragment >
  )
}

PaymentMethodsTable.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  billing: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    billing: state.Billing,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(PaymentMethodsTable)));