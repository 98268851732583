import { get } from "../helpers/api_helper";
const _ = require('lodash');

class MessageService {

  fetchMessages(projectId, direction, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }
    const directionParam = direction ? direction : null

    let url = `/projects/${projectId}/messages?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    if (directionParam) {
      url = url + `&direction=${directionParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchMessageDetails(projectId, messageId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/messages/${messageId}`)
        .then(response => {
          const message = _.get(response, 'message', {});
          resolve(message);
        }).catch(error => {
          reject(error);
        })
    });

  }

}

export const messageService = new MessageService();