/* BILLING */
export const GET_PAYMENTS_HISTORY_FOR_PROJECT_REQUEST = "GET_PAYMENTS_HISTORY_FOR_PROJECT_REQUEST";
export const GET_PAYMENTS_HISTORY_FOR_PROJECT_SUCCESS = "GET_PAYMENTS_HISTORY_FOR_PROJECT_SUCCESS";

export const GET_PAYMENT_METHODS_FOR_PROJECT_REQUEST = "GET_PAYMENT_METHODS_FOR_PROJECT_REQUEST";
export const GET_PAYMENT_METHODS_FOR_PROJECT_SUCCESS = "GET_PAYMENT_METHODS_FOR_PROJECT_SUCCESS";

export const GET_SETUP_INTENT_FOR_PAYMENT_METHOD_REQUEST = "GET_SETUP_INTENT_FOR_PAYMENT_METHOD_REQUEST";
export const GET_SETUP_INTENT_FOR_PAYMENT_METHOD_SUCCESS = "GET_SETUP_INTENT_FOR_PAYMENT_METHOD_SUCCESS";

export const DELETE_PAYMENT_METHOD_REQUEST = "DELETE_PAYMENT_METHOD_REQUEST";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";

export const TOP_UP_BALANCE_REQUEST = "TOP_UP_BALANCE_REQUEST";
export const TOP_UP_BALANCE_SUCCESS = "TOP_UP_BALANCE_SUCCESS";

export const STRIPE_API_ERROR = "STRIPE_API_ERROR";

export const BILLING_API_ERROR = "BILLING_API_ERROR";
