import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Redirect, withRouter, useParams, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getBrandDetails,
  updateToken
} from "../../store/actions";

const BrandDetails = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  // getting brandId fromURL
  const { brandId: currentBrandId } = useParams();
  const { tokenId: currentTokenId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const brandDetails = useSelector((state) => state.Csp.brandDetails);

  const [redirectToBrandsList, setRedirectToBrandsList] = useState(false); // a flag to redirect back

  const isLoading = useSelector((state) => state.Csp.loading);
  const apiError = useSelector((state) => state.Csp.error);

  useEffect(() => {
    if (currentProjectId && currentBrandId) {
      dispatch(getBrandDetails(currentProjectId, currentBrandId));
    }
  }, [dispatch, currentProjectId, currentBrandId]);

  const onCancelUpdateTokenClick = () => {
    setRedirectToBrandsList(true);
  }


  // formik cannot handle initial values of checkboxes (they are always unchecked) for some reason. so the workaround is to handle them manually
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: brandDetails || {}, // initial values are equal to the brandDetail object.
    validationSchema: Yup.object({
      // TODO: validation is to be done
      //tokenName: Yup.string().required("Please enter token name"),
      //tokenScopes: Yup.array().min(1, "Please select at least one scope")
    }),
    onSubmit: async (values) => {
     dispatch(updateToken(currentProjectId, currentTokenId, values.tokenName, values.tokenScopes, () => history.push(`/projects/${currentProjectId}/api/tokens?redirect_status=update-token-succeeded`)))
    }
  });

  return (
    <React.Fragment>
      {redirectToBrandsList &&
        <Redirect push to={`/projects/${currentProjectId}/csp/brands`} />
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Campaign Registry" breadcrumbItem="Brand Details" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {isLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!isLoading && apiError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
                  {!isLoading && brandDetails && currentBrandId &&
                    <>
                      <Form
                        className="form-group top-up-balance"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Label className="form-label">Display Name</Label>
                          <InputGroup>
                            <Input
                              name="displayName"
                              placeholder="Enter Display Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.displayName || ""}
                              invalid={
                                validation.touched.displayName && validation.errors.displayName ? true : false
                              }
                            />
                            {validation.touched.displayName && validation.errors.displayName ? (
                              <FormFeedback type="invalid">{validation.errors.displayName}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Entity Type (TODO: make it a dropdown)</Label>
                          <InputGroup>
                            <Input
                              name="entityType"
                              placeholder="Enter Entity Type"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.entityType || ""}
                              invalid={
                                validation.touched.entityType && validation.errors.entityType ? true : false
                              }
                            />
                            {validation.touched.entityType && validation.errors.entityType ? (
                              <FormFeedback type="invalid">{validation.errors.entityType}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">First Name</Label>
                          <InputGroup>
                            <Input
                              name="firstName"
                              placeholder="Enter First Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstName || ""}
                              invalid={
                                validation.touched.firstName && validation.errors.firstName ? true : false
                              }
                            />
                            {validation.touched.firstName && validation.errors.firstName ? (
                              <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Last Name</Label>
                          <InputGroup>
                            <Input
                              name="lastName"
                              placeholder="Enter Last Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastName || ""}
                              invalid={
                                validation.touched.lastName && validation.errors.lastName ? true : false
                              }
                            />
                            {validation.touched.lastName && validation.errors.lastName ? (
                              <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Email</Label>
                          <InputGroup>
                            <Input
                              name="email"
                              placeholder="Enter Email"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Company Name</Label>
                          <InputGroup>
                            <Input
                              name="companyName"
                              placeholder="Enter Company Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.companyName || ""}
                              invalid={
                                validation.touched.companyName && validation.errors.companyName ? true : false
                              }
                            />
                            {validation.touched.companyName && validation.errors.companyName ? (
                              <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">EIN</Label>
                          <InputGroup>
                            <Input
                              name="ein"
                              placeholder="Enter EIN"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.ein || ""}
                              invalid={
                                validation.touched.ein && validation.errors.ein ? true : false
                              }
                            />
                            {validation.touched.ein && validation.errors.ein ? (
                              <FormFeedback type="invalid">{validation.errors.ein}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">EIN Issuing Country</Label>
                          <InputGroup>
                            <Input
                              name="einIssuingCountry"
                              placeholder="Enter EIN Issuing Country"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.einIssuingCountry || ""}
                              invalid={
                                validation.touched.einIssuingCountry && validation.errors.einIssuingCountry ? true : false
                              }
                            />
                            {validation.touched.einIssuingCountry && validation.errors.einIssuingCountry ? (
                              <FormFeedback type="invalid">{validation.errors.einIssuingCountry}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Phone</Label>
                          <InputGroup>
                            <Input
                              name="phone"
                              placeholder="Enter Phone"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone || ""}
                              invalid={
                                validation.touched.phone && validation.errors.phone ? true : false
                              }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                              <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Street</Label>
                          <InputGroup>
                            <Input
                              name="street"
                              placeholder="Enter Street"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.street || ""}
                              invalid={
                                validation.touched.street && validation.errors.street ? true : false
                              }
                            />
                            {validation.touched.street && validation.errors.street ? (
                              <FormFeedback type="invalid">{validation.errors.street}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">City</Label>
                          <InputGroup>
                            <Input
                              name="street"
                              placeholder="Enter City"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.city || ""}
                              invalid={
                                validation.touched.city && validation.errors.city ? true : false
                              }
                            />
                            {validation.touched.city && validation.errors.city ? (
                              <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">State</Label>
                          <InputGroup>
                            <Input
                              name="state"
                              placeholder="Enter State"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.state || ""}
                              invalid={
                                validation.touched.state && validation.errors.state ? true : false
                              }
                            />
                            {validation.touched.state && validation.errors.state ? (
                              <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Postal Code</Label>
                          <InputGroup>
                            <Input
                              name="postalCode"
                              placeholder="Enter Postal Code"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.postalCode || ""}
                              invalid={
                                validation.touched.postalCode && validation.errors.postalCode ? true : false
                              }
                            />
                            {validation.touched.postalCode && validation.errors.postalCode ? (
                              <FormFeedback type="invalid">{validation.errors.postalCode}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Country</Label>
                          <InputGroup>
                            <Input
                              name="country"
                              placeholder="Enter Country"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.country || ""}
                              invalid={
                                validation.touched.country && validation.errors.country ? true : false
                              }
                            />
                            {validation.touched.country && validation.errors.country ? (
                              <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Stock Symbol</Label>
                          <InputGroup>
                            <Input
                              name="stockSymbol"
                              placeholder="Enter Stock Symbol"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.stockSymbol || ""}
                              invalid={
                                validation.touched.stockSymbol && validation.errors.stockSymbol ? true : false
                              }
                            />
                            {validation.touched.stockSymbol && validation.errors.stockSymbol ? (
                              <FormFeedback type="invalid">{validation.errors.stockSymbol}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Stock Exchange</Label>
                          <InputGroup>
                            <Input
                              name="stockExchange"
                              placeholder="Enter Stock Exchange"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.stockExchange || ""}
                              invalid={
                                validation.touched.stockExchange && validation.errors.stockExchange ? true : false
                              }
                            />
                            {validation.touched.stockExchange && validation.errors.stockExchange ? (
                              <FormFeedback type="invalid">{validation.errors.stockExchange}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Website</Label>
                          <InputGroup>
                            <Input
                              name="website"
                              placeholder="Enter Website"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.website || ""}
                              invalid={
                                validation.touched.website && validation.errors.website ? true : false
                              }
                            />
                            {validation.touched.website && validation.errors.website ? (
                              <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Brand Relationship</Label>
                          <InputGroup>
                            <Input
                              name="brandRelationship"
                              placeholder="Enter Relationship"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.brandRelationship || ""}
                              invalid={
                                validation.touched.brandRelationship && validation.errors.brandRelationship ? true : false
                              }
                            />
                            {validation.touched.brandRelationship && validation.errors.brandRelationship ? (
                              <FormFeedback type="invalid">{validation.errors.brandRelationship}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Universal EIN</Label>
                          <InputGroup>
                            <Input
                              name="universalEin"
                              placeholder="Enter Universal EIN"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.universalEin || ""}
                              invalid={
                                validation.touched.universalEin && validation.errors.universalEin ? true : false
                              }
                            />
                            {validation.touched.universalEin && validation.errors.universalEin ? (
                              <FormFeedback type="invalid">{validation.errors.universalEin}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Created:</Label>
                          <div>{!validation.values.createdTimestampMs ? '' : new Date(validation.values.createdTimestampMs).toLocaleString()}</div>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                            <Button
                              type="reset"
                              color="danger"
                              outline
                              className="w-md"
                              onClick={onCancelUpdateTokenClick}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BrandDetails.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  tokens: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    csp: state.Csp,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(BrandDetails)));