import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import ResetPassword from './auth/resetpwd/reducer';

// authUser
import AuthUser from './authUser/reducer';

// billing
import Billing from './billing/reducer';

// project
import Project from './project/reducer';

// token
import Token from './token/reducer';

// profile
import Profile from './profile/reducer';

// telephone numbers
import Numbers from './numbers/reducer';

// csp
import Csp from './csp/reducer';

// events
import Event from './event/reducer';

// messages
import Message from './message/reducer';

import AuthChangePassword from './auth/changepwd/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ResetPassword,
  AuthUser,
  Billing,
  Project,
  Token,
  Numbers,
  Csp,
  Event,
  Message,
  Profile,
  AuthChangePassword,
})

export default rootReducer;
