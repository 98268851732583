import PropTypes from "prop-types"
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory, Link, useLocation } from 'react-router-dom';
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError, googleSignInWithPopup } from "../../store/actions";

import { google } from "../../config";

import GoogleButton from 'react-google-button';

// import images
import loginLogo from "../../assets/images/logo.svg";

const Register = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const redirectUrl = new URLSearchParams(window?.location?.search).get(
    'r'
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      passwordRepeat: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),
      password: Yup.string().required("Please enter your password").min(6, "Password should be at least 6 characters long"),
      passwordRepeat: Yup.string()
        .max(3000, "Password is too long")
        .test(
          "passwordsAreEqual",
          "Passwords are not equal",
          (value, context) => value === context.parent.password,
        ),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values, () => {
        setTimeout(() => {
          if (redirectUrl) {
            history.push(redirectUrl);
          } else {
            history.push('/dashboard');
          }
        }, 5000); // redirect after 5 secs
      }
      ));
    }
  });

  const { user, registrationError } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError ? (state.Account.registrationError.includes('email-already-in-use') ? ' The email address is already in use by another account' : 'Something went wrong') : '', // this will show the "account in use" error if Firebase returned it, or a generic error if any.
    loading: state.Account.loading,
  }));


  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(registerUser(values));
  // }

  useEffect(() => {
    dispatch(apiError(""));
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  }, [dispatch]);

  const googleLogin = () => {
    dispatch(googleSignInWithPopup(props.history, 'google'));
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={loginLogo} alt="" height="44" className="logo logo-dark" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Register Account</h5>
                    <p className="text-muted">Get your Corelink Telecom account now.</p>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <>
                          <Alert color="success">
                            User was registered successfully. Redirecting...
                          </Alert>
                        </>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Repeat Password</Label>
                        <Input
                          name="passwordRepeat"
                          type="password"
                          placeholder="Enter Password Again"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.passwordRepeat || ""}
                          invalid={
                            validation.touched.passwordRepeat && validation.errors.passwordRepeat ? true : false
                          }
                        />
                        {validation.touched.passwordRepeat && validation.errors.passwordRepeat ? (
                          <FormFeedback type="invalid">{validation.errors.passwordRepeat}</FormFeedback>
                        ) : null}
                      </div>
                      {/*
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="auth-terms-condition-check" />
                        <label className="form-check-label" htmlFor="auth-terms-condition-check">I accept <Link to="#" className="text-dark">Terms and Conditions</Link></label>
                      </div>
                        */}

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-3 text-center">
                        {google.CLIENT_ID !== '' &&
                          <h5 className="font-size-14 mb-3">OR</h5>
                        }

                        <ul className="list-inline">
                          {google.CLIENT_ID !== '' &&
                            <GoogleButton
                              className='w-100'
                              type="light" // can be light or dark
                              onClick={() => { googleLogin(); }}
                            />
                          }
                        </ul>
                      </div>

                      {/*
                      <div className="mt-4 text-center">
                        <div className="signin-other-title">
                          <h5 className="font-size-14 mb-3 title">Sign up using</h5>
                        </div>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-info text-white border-info"
                            >
                              <i className="mdi mdi-twitter" />
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                        */}
                      <div className="mt-4 text-center">
                        <p className="text-muted mb-0">Already have an account ? <Link to={`/login${search}`} className="fw-medium text-primary">Sign In</Link></p>
                      </div>

                    </Form>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Corelink Telecom LLC</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(Register);

Register.propTypes = {
  registerUser: PropTypes.func,
  // registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}



