import { get, post, del } from "../helpers/api_helper";
const _ = require('lodash');

class BillingService {

  fetchPaymentsHistory(projectId, limit, offset) {

    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/billing/payments/history?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchPaymentMethods(projectId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/billing/payments/methods`)
        .then(response => {
          resolve(response); // we need all values from response (including totalCount)
        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchSetupIntentForPaymentMethod(projectId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/billing/payments/methods/setup`)
        .then(response => {
          const secret = _.get(response, 'secret', '');
          resolve(secret);
        }).catch(error => {
          reject(error);
        })
    });

  }

  deletePaymentMethod(projectId, paymentMethodId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/billing/payments/methods/${paymentMethodId}`)
        .then( () => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  topUpBalance(projectId, paymentMethodId, amount) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/billing/balance/top-up`,
        {
          paymentMethodId,
          amount
        }
      ).then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    });

  }

}

export const billingService = new BillingService();