import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getBrandsForProject
} from "../../store/actions";

import '../datatables.scss';


const Brands = () => {

  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const brandsList = useSelector((state) => state.Csp.brandsList);
  const loading = useSelector((state) => state.Csp.loading);
  const apiError = useSelector((state) => state.Csp.error);
  const [redirectToDetailsBrandId, setRedirectToDetailsBrandId] = useState(''); // a flag to redirect to UpdateBrand form (with particular brandId)
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  const redirectStatus = new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getBrandsForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId, redirectStatus]);

  useEffect(() => {

    if (redirectStatus === 'delete-brand-succeeded') {
      setSuccessMessage("Brand was successfully deleted");
    }

    if (redirectStatus === 'update-brand-succeeded') {
      setSuccessMessage("Brand was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }
  }, [redirectStatus]);

  const onUpdateBrandClick = (brandId) => {
    setRedirectToDetailsBrandId(brandId); // show the details brand form for a specific brandId
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Campaign Registry" breadcrumbItem="Project Brands" />
          <Card>
            <CardBody>
              {redirectToDetailsBrandId && <Redirect push to={`/projects/${currentProjectId}/csp/brands/${redirectToDetailsBrandId}`} />}
              {loading && <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              }
              {!loading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
              {!loading && apiError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
              {!loading &&
                <div className="actions clearfix">
                  <ul>
                    <li
                    >
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md"
                        onClick={() => alert('not_implemented')}
                      >
                        Register Brand
                      </Button>
                    </li>
                  </ul>
                </div>
              }
              {!loading &&
                <>
                  <Row>
                    <Col className="col-12">
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Created Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(!brandsList || !Array.isArray(brandsList) || brandsList.length === 0) && <tr><td colSpan={4} className="text-center">No brands registered yet</td></tr>}
                          {
                            brandsList && Array.isArray(brandsList) && brandsList.length > 0 && brandsList.map((b, i) => <tr key={i} className="align-middle">
                              <td style={{ width: "10%" }}>
                                <i className="uil-trash-alt" title="Deregister this brand" role="button" onClick={() => alert('not_implemented')}></i>
                              </td>
                              <td style={{ width: "40%" }}><Button style={{ padding: "0" }} color="link" className="waves-effect" onClick={() => onUpdateBrandClick(b.brandId)} title="Go to brand details">{b.displayName}</Button></td>
                              <td style={{ width: "20%" }}>not_implemented</td>
                              <td style={{ width: "30%" }}>{!b.createdTimestampMs ? '' : new Date(b.createdTimestampMs).toLocaleString()}</td>
                            </tr>)
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Brands.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  Csp: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    csp: state.Csp,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Brands)));