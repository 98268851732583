/* CSP */
export const GET_BRANDS_FOR_PROJECT_REQUEST = "GET_BRANDS_FOR_PROJECT_REQUEST";
export const GET_BRANDS_FOR_PROJECT_SUCCESS = "GET_BRANDS_FOR_PROJECT_SUCCESS";

export const GET_BRAND_DETAILS_REQUEST = "GET_BRAND_DETAILS_REQUEST";
export const GET_BRAND_DETAILS_SUCCESS = "GET_BRAND_DETAILS_SUCCESS";

export const GET_CAMPAIGNS_REQUEST = "GET_CAMPAIGNS_REQUEST";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";

export const CSP_API_ERROR = "CSP_API_ERROR";
