import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";


import ProjectInfoCard from "./ProjectInfoCard";
import LatestEventsCard from "./LatestEventsCard";
import EmptyProjectsList from "../EmptyProjectsList";

import { userHasProjects } from "../../helpers/utils";

const Dashboard = () => {

  const projectsList = useSelector((state) => state.AuthUser.projectsList);
  const isLoading = useSelector((state) => state.AuthUser.loading);

  return (
    <React.Fragment>
      {isLoading && <div className="page-content"><div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>
      }
      {!isLoading && !userHasProjects(projectsList) &&
        <EmptyProjectsList />
      }
      {!isLoading && userHasProjects(projectsList) &&
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Corelink" breadcrumbItem="Home" />

            <Row>
              <Col /*xl={8} */>
                <ProjectInfoCard />
              </Col>
              {/* temporary disable because it needs some work 
            <Col xl={4}>
 
              <PhoneNumbersStatsChart />
            </Col>
                         */}
            </Row>
            <Row>
              <Col>
                <LatestEventsCard />
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  );
};

export default Dashboard;