// @flow
import {
  GET_MESSAGES_FOR_PROJECT_REQUEST,
  GET_MESSAGES_FOR_PROJECT_SUCCESS,
  GET_MESSAGE_DETAILS_REQUEST,
  GET_MESSAGE_DETAILS_SUCCESS,
  MESSAGE_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,

  messagesList: [],
  messagesLimit: 20,
  messagesOffset: 0,

  messageDetails: undefined
};

const Message = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MESSAGES_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_MESSAGES_FOR_PROJECT_SUCCESS:
      let msgs = [];
      if (Array.isArray(action.payload.messagesList) && action.payload.messagesList.length > 0) {
        msgs = action.payload.messagesList;
      }

      return {
        ...state,
        loading: false,
        messagesList: msgs,
        messagesLimit: action.payload.messagesLimit,
        messagesOffset: action.payload.messagesOffset,
      }

    case GET_MESSAGE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        messageDetails: undefined, // this is important
        error: ''
      };

    case GET_MESSAGE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        messageDetails: action.payload,
      }

    case MESSAGE_API_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default Message;
