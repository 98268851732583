import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getProjectDetails,
  getAuthUserProjects,
  updateProject
} from "../../store/actions";

const ProjectDetails = () => {

  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const projectName = useSelector((state) => state.Project?.projectDetails?.project?.name);
  const isLoading = useSelector((state) => state.Project.loading);
  const projectAPIError = useSelector((state) => state.Project.error);

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  useEffect(() => {
    dispatch(getProjectDetails(currentProjectId)); // we don't have to call this, it creates a double HTTP request. but in the same time we cannot rely on the "magic" with projectDetails fetch after every page navigation. so let's just keep it here.
  }, [dispatch, currentProjectId]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      projectName: projectName,
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required("Please enter project name").max(18, "Name is too long"),
    }),
    onSubmit: async (values) => {
      dispatch(updateProject(currentProjectId, values.projectName, () => {
        setSuccessMessage("Project was successfully updated");
        dispatch(getAuthUserProjects());
      }
      ));
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Project" breadcrumbItem="Project Details" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {isLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!isLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
                  {!isLoading && projectAPIError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
                  {!isLoading &&
                    <>
                      <Form
                        className="form-group top-up-balance"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Label className="form-label">Project ID</Label>
                          <p>{currentProjectId}</p>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Project Name</Label>
                          <InputGroup>
                            <Input
                              name="projectName"
                              placeholder="Enter Project Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.projectName || ""}
                              invalid={
                                validation.touched.projectName && validation.errors.projectName ? true : false
                              }
                            />
                            {validation.touched.projectName && validation.errors.projectName ? (
                              <FormFeedback type="invalid">{validation.errors.projectName}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProjectDetails.propTypes = {
  t: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ProjectDetails)));