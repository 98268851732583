import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

import InviteUserDialog from "./InviteUserDialog";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getAuthUsersForProject,
  deleteUserFromProject
} from "../../store/actions";

import '../datatables.scss';

const ownerRoleName = 'owner';

const Users = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const usersList = useSelector((state) => state.AuthUser.projectUsersList);
  const isLoading = useSelector((state) => state.AuthUser.loading);
  const usersAPIError = useSelector((state) => state.AuthUser.error);

  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  const redirectStatus = new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getAuthUsersForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId, redirectStatus]);

  useEffect(() => {

    if (redirectStatus === 'delete-user-succeeded') {
      setSuccessMessage("User was successfully deleted from the project");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }
  }, [redirectStatus]);

  const onAddUserClick = () => {
    setIsInviteUserDialogOpen(true);
  }

  const hideInviteUserDialog = () => {
    setIsInviteUserDialogOpen(false);
  }

  const onDeleteUserClick = (userId) => {
    if (window.confirm("Are you sure you want to delete this user from the project?") === true) {
      dispatch(deleteUserFromProject(currentProjectId, userId, () => {
        history.push(`/projects/${currentProjectId}/settings/users?redirect_status=delete-user-succeeded`);
      }))
    }
  }

  const getRoleName = (r) => {
    if (!r) {
      return "";
    }
    if (r.toLowerCase() === "owner") {
      return "Owner";
    }

    if (r.toLowerCase() === "manager") {
      return "Manager";
    }

    if (r.toLowerCase() === "editor") {
      return "Editor";
    }

    if (r.toLowerCase() === "viewer") {
      return "Viewer";
    }

    return "";
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Project Users" />
          <Card>
            <CardBody>
              {isLoading && <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              }
              {!isLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
              {!isLoading && usersAPIError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
              {!isLoading &&
                <div className="actions clearfix">
                  <ul>
                    <li
                    >
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md"
                        onClick={onAddUserClick}
                      >
                        Invite User
                      </Button>
                    </li>
                  </ul>
                </div>
              }
              {!isLoading &&
                <>
                  <Row>
                    <Col className="col-12">
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(!usersList || !Array.isArray(usersList) || usersList.length === 0) && <tr><td colSpan={4} className="text-center">No users yet</td></tr>}
                          {
                            usersList && Array.isArray(usersList) && usersList.length > 0 && usersList.map((u, i) => <tr key={i} className="align-middle">
                              <td style={{ width: "10%" }}>
                                {u.roles && Array.isArray(u.roles) && !u.roles.includes(ownerRoleName) && /* disabling deletion for an owner */
                                  <i className="uil-trash-alt" title="Remove this user from the project" role="button" onClick={() => onDeleteUserClick(u.userId)}></i>
                                }
                              </td>
                              <td>{u.email}</td>
                              <td>{u.fullName}</td>
                              <td>{u.roles && Array.isArray(u.roles) && <span>{u.roles.map(r => getRoleName(r)).join(", ")}</span>}</td>
                            </tr>)
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </Container>
      </div>

      <InviteUserDialog isOpen={isInviteUserDialogOpen} closeDialog={hideInviteUserDialog} />
    </React.Fragment>
  )
}

Users.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Users)));