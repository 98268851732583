import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import ProfileDetailsCard from './ProfileDetailsCard';
import ChangePasswordCard from './ChangePasswordCard';


const ProfileDetails = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Profile" breadcrumbItem="Profile Details" />
          <Row>
            <Col className="col-12">
              <ProfileDetailsCard />

              <ChangePasswordCard />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ProfileDetails));