import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Button, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../components/Common/Breadcrumb';

// Formik Validation
import { useFormik } from "formik";

import {
  getInviteDetails,
  acceptInvite
} from "../store/actions";

const InviteDetails = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  // getting invited fromURL
  const { inviteId: currentInviteId } = useParams();

  const inviteDetails = useSelector((state) => state.AuthUser?.inviteDetails);
  const isLoading = useSelector((state) => state.AuthUser.loading);
  const apiError = useSelector((state) => state.AuthUser.error);

  const [successMessage] = useState(''); // to show a success message

  useEffect(() => {
    dispatch(getInviteDetails(currentInviteId));
  }, [dispatch, currentInviteId]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      inviteDetails: inviteDetails,
    },
    onSubmit: async () => {
      dispatch(acceptInvite(currentInviteId, () => history.push(`/projects/${inviteDetails?.projectId}/dashboard?redirect_status=accept-invite-succeeded`)));
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Invite" breadcrumbItem="Invite Details" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {isLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!isLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
                  {!isLoading && apiError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
                  {!isLoading && inviteDetails &&
                    <>
                      <Form
                        className="form-group top-up-balance"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <p>

                            <strong>{inviteDetails.invitedByFullName ? inviteDetails.invitedByFullName : inviteDetails.invitedByEmail}</strong> invited you to join <strong>{inviteDetails.projectName}</strong> project.<br />
                            To accept the invite pease click the button below.

                          </p>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Accept
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

InviteDetails.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(InviteDetails)));