import PropTypes from 'prop-types';
import React, { useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

// Redux
import { withRouter, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, googleSignInWithPopup } from "../../store/actions";

// import images
import loginLogo from "../../assets/images/logo.svg";

//Import config
import { google } from "../../config";

import GoogleButton from 'react-google-button';

const Login = (props) => {

  const dispatch = useDispatch();
  const { search } = useLocation();

  let redirectUrl = new URLSearchParams(window?.location?.search).get(
    'r'
  );

  if (!redirectUrl && props?.location?.state?.from) { // props?.location?.state?.from is being set when we are redirected from empty user. see Authmiddleware.js
    redirectUrl = `${props?.location?.state?.from?.host ?? ''}${props?.location?.state?.from?.pathname ?? ''}${props?.location?.state?.from?.search ?? ''}`;
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),
      password: Yup.string().required("Please enter your password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history, redirectUrl));
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const googleLogin = () => {
    dispatch(googleSignInWithPopup(props.history, 'google'));
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={loginLogo} alt="" height="44" className="logo logo-dark" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome!</h5>
                    <p className="text-muted">Sign in to continue to Corelink Telecom Dashboard.</p>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">The user name and password combination is not valid</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to={`/forgot-password${search}`} className="text-muted">Forgot password?</Link>
                        </div>
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Sign In
                        </button>
                      </div>

                      <div className="mt-3 text-center">
                        {google.CLIENT_ID !== '' &&
                          <h5 className="font-size-14 mb-3">OR</h5>
                        }

                        <ul className="list-inline">
                          {google.CLIENT_ID !== '' &&
                            <GoogleButton
                              className='w-100'
                              type="light" // can be light or dark
                              onClick={() => { googleLogin(); }}
                            />
                          }
                        </ul>
                      </div>



                      <div className="mt-4 text-center">
                        <p className="mb-0">Don&apos;t have an account ? <a href={`/register${search}`} className="fw-medium text-primary"> Sign up now </a> </p>
                      </div>


                    </Form>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Corelink Telecom LLC</p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}