import {
  GET_EVENTS_FOR_PROJECT_REQUEST,
  GET_EVENTS_FOR_PROJECT_SUCCESS,
  EVENT_API_ERROR
} from "./actionTypes";

export const getEventsForProject = (projectId, limit, offset) => {
  return {
    type: GET_EVENTS_FOR_PROJECT_REQUEST,
    payload: { projectId, limit, offset }
  }
};

export const getEventsForProjectSuccess = ({ events: eventsList, offset: eventsOffset, limit: eventsLimit }) => {
  return {
    type: GET_EVENTS_FOR_PROJECT_SUCCESS,
    payload: { eventsList, eventsOffset, eventsLimit }
  }
};

export const apiError = error => {
  return {
    type: EVENT_API_ERROR,
    payload: error,
  }
}