import {
  GET_PROFILE_DETAILS_REQUEST,
  GET_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_REQUEST,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  PROFILE_API_ERROR
} from "./actionTypes";

export const getProfileDetails = () => {
  return {
    type: GET_PROFILE_DETAILS_REQUEST,
  }
};

export const getProfileDetailsSuccess = profileDetails => {
  return {
    type: GET_PROFILE_DETAILS_SUCCESS,
    payload: profileDetails
  }
};

export const updateProfile = (fullName, phone, companyName, callback) => {
  return {
    type: UPDATE_PROFILE_DETAILS_REQUEST,
    payload: { fullName, phone, companyName, callback }
  }
};

export const updateProfileSuccess = () => {
  return {
    type: UPDATE_PROFILE_DETAILS_SUCCESS,
  }
};

export const apiError = error => {
  return {
    type: PROFILE_API_ERROR,
    payload: error,
  }
}