/* TELEPHONE NUMBERS */
export const GET_NUMBERS_FOR_PROJECT_REQUEST = "GET_NUMBERS_FOR_PROJECT_REQUEST";
export const GET_NUMBERS_FOR_PROJECT_SUCCESS = "GET_NUMBERS_FOR_PROJECT_SUCCESS";

export const GET_NUMBER_DETAILS_REQUEST = "GET_NUMBER_DETAILS_REQUEST";
export const GET_NUMBER_DETAILS_SUCCESS = "GET_NUMBER_DETAILS_SUCCESS";

export const SEARCH_INVENTORY_FOR_PROJECT_REQUEST = "SEARCH_INVENTORY_FOR_PROJECT_REQUEST";
export const SEARCH_INVENTORY_FOR_PROJECT_SUCCESS = "SEARCH_INVENTORY_FOR_PROJECT_SUCCESS";

export const DELETE_NUMBER_REQUEST = "DELETE_NUMBER_REQUEST";
export const DELETE_NUMBER_SUCCESS = "DELETE_NUMBER_SUCCESS";

export const NUMBER_API_ERROR = "NUMBER_API_ERROR";
