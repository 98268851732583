import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { RESET_PASSWORD } from "./actionTypes";
import { userResetPasswordSuccess, userResetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* resetPassword({ payload: { code, newPassword } }) {
  try {
      const response = yield call(fireBaseBackend.resetPassword, code, newPassword);
      if (response) {
        yield put(
          userResetPasswordSuccess(
            "New password was set successfully"
          )
        )
      }
  } catch (error) {
    yield put(userResetPasswordError(error))
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)])
}

export default resetPasswordSaga;
