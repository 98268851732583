// @flow
import {
  GET_TOKENS_FOR_PROJECT_REQUEST,
  GET_TOKENS_FOR_PROJECT_SUCCESS,
  GET_TOKEN_DETAILS_REQUEST,
  GET_TOKEN_DETAILS_SUCCESS,
  UPDATE_TOKEN_DETAILS_REQUEST,
  UPDATE_TOKEN_DETAILS_SUCCESS,
  DELETE_TOKEN_REQUEST,
  DELETE_TOKEN_SUCCESS,
  TOKEN_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,
  tokensList: [],
  tokenDetails: undefined
};

const Token = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TOKENS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_TOKENS_FOR_PROJECT_SUCCESS:
      let tokens = [];
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        tokens = action.payload;
      }

      return {
        ...state,
        loading: false,
        tokensList: tokens
      }

    case GET_TOKEN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        tokenDetails: undefined, // this is important for the updateToken form correctly showing the scopes checkboxes
        error: ''
      };

    case GET_TOKEN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        tokenDetails: action.payload,
      }

    case DELETE_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case DELETE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_TOKEN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case UPDATE_TOKEN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TOKEN_API_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Token;
