// @flow
import {
  GET_NUMBERS_FOR_PROJECT_REQUEST,
  GET_NUMBERS_FOR_PROJECT_SUCCESS,
  GET_NUMBER_DETAILS_REQUEST,
  GET_NUMBER_DETAILS_SUCCESS,
  SEARCH_INVENTORY_FOR_PROJECT_REQUEST,
  SEARCH_INVENTORY_FOR_PROJECT_SUCCESS,
  DELETE_NUMBER_REQUEST,
  DELETE_NUMBER_SUCCESS,
  NUMBER_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,

  telephoneNumbersList: [],
  telephoneNumbersLimit: 20,
  telephoneNumbersOffset: 0,
  telephoneNumbersTotalCount: 0,

  telephoneNumberDetails: undefined,

  inventoryTelephoneNumbersList: undefined, // this is important to avoid loading inventory twice (see SearchInventory.js for details)
  inventoryTelephoneNumbersTotalCount: 0,
  inventoryTelephoneNumberType: "local",
  inventoryTelephoneNumbersRegionName: "",
  inventoryTelephoneNumbersLocationName: "",
  inventoryTelephoneNumbersContains: "",
  inventoryTelephoneNumbersLata: "",
  inventoryTelephoneNumbersMaxResults: 99,
  inventoryTelephoneNumbersCountryCode: "US",
};

const Numbers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NUMBERS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_NUMBERS_FOR_PROJECT_SUCCESS:
      let tns = [];
      if (Array.isArray(action.payload.telephoneNumbersList) && action.payload.telephoneNumbersList.length > 0) {
        tns = action.payload.telephoneNumbersList;
      }

      return {
        ...state,
        loading: false,
        telephoneNumbersList: tns,
        telephoneNumbersLimit: action.payload.telephoneNumbersLimit,
        telephoneNumbersOffset: action.payload.telephoneNumbersOffset,
        telephoneNumbersTotalCount: action.payload.telephoneNumbersTotalCount,
      }

    case GET_NUMBER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        telephoneNumberDetails: undefined, // this is important
        error: ''
      };

    case GET_NUMBER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        telephoneNumberDetails: action.payload,
      }

    case SEARCH_INVENTORY_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        inventoryTelephoneNumberType: action.payload.telephoneNumberType,
        inventoryTelephoneNumbersRegionName: action.payload.regionName,
        inventoryTelephoneNumbersLocationName: action.payload.locationName,
        inventoryTelephoneNumbersContains: action.payload.containsFilter,
        inventoryTelephoneNumbersMaxResults: action.payload.maxResults,
        inventoryTelephoneNumbersCountryCode: action.payload.countryCode,
        inventoryTelephoneNumbersLata: action.payload.lata,
        inventoryTelephoneNumbersList: [],
      };

    case SEARCH_INVENTORY_FOR_PROJECT_SUCCESS:
      let inventoryTns = [];
      if (Array.isArray(action.payload.inventoryTelephoneNumbersList) && action.payload.inventoryTelephoneNumbersList.length > 0) {
        inventoryTns = action.payload.inventoryTelephoneNumbersList;
      }

      return {
        ...state,
        loading: false,
        inventoryTelephoneNumbersList: inventoryTns,
        inventoryTelephoneNumbersTotalCount: action.payload.inventoryTelephoneNumbersTotalCount,
      }

    case DELETE_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case DELETE_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case NUMBER_API_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default Numbers;
