import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Redirect, withRouter, useParams, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getNumberDetails,
  getNumbersForProject,
  deleteNumber
} from "../../store/actions";

import { isNullOrUndefined } from '../../helpers/utils';

import { formatTNLocation, formatTNType } from './utils';

const NumberDetails = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  // getting tokenId fromURL
  const { telephoneNumberId: currentTelephoneNumberId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const telephoneNumberDetails = useSelector((state) => state.Numbers?.telephoneNumberDetails);
  const isLoading = useSelector((state) => state.Numbers.loading);
  const apiError = useSelector((state) => state.Numbers.error);
  const [redirectToNumbersList, setRedirectToNumbersList] = useState(false); // a flag to redirect back
  const [redirectToDetailsCampaignId, setRedirectToDetailsCampaignId] = useState(''); // a flag to redirect back
  const telephoneNumbersLimit = useSelector((state) => state.Numbers.telephoneNumbersLimit);
  const pageSize = telephoneNumbersLimit || 20;

  useEffect(() => {
    if (currentProjectId && currentTelephoneNumberId) {
      dispatch(getNumberDetails(currentProjectId, currentTelephoneNumberId));
    }
  }, [dispatch, currentProjectId, currentTelephoneNumberId]);

  const onBackClick = () => {
    setRedirectToNumbersList(true);
  }

  const onCampaignDetailsClick = (campaignId) => {
    setRedirectToDetailsCampaignId(campaignId);
  }

  const onDeleteNumberClick = () => {
    if (window.confirm(`Are you sure you want to release ${telephoneNumberDetails?.telephoneNumber}?`) === true) {
      dispatch(deleteNumber(currentProjectId, currentTelephoneNumberId, () => {
        dispatch(getNumbersForProject(currentProjectId, pageSize, 0));
        history.push(`/projects/${currentProjectId}/numbers?redirect_status=delete-tn-succeeded&tn=${encodeURIComponent(telephoneNumberDetails?.telephoneNumber)}`);
      }))
    }
  }


  return (
    <React.Fragment>
      {redirectToNumbersList &&
        <Redirect push to={`/projects/${currentProjectId}/numbers`} />
      }
      {redirectToDetailsCampaignId &&
        <Redirect push to={`/projects/${currentProjectId}/csp/campaigns/${redirectToDetailsCampaignId}`} />
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Phone Numbers" breadcrumbItem="Phone Number Details" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {isLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!isLoading && apiError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
                  {!isLoading &&
                    <>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Telephone Number ID</Label>
                          <p>{telephoneNumberDetails?.telephoneNumberId}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">E.164</Label>
                          <p>{telephoneNumberDetails?.telephoneNumber}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Type</Label>
                          <p>{formatTNType(telephoneNumberDetails)}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Purchase Date</Label>
                          <p>{!telephoneNumberDetails?.purchasedTimestampMs ? '' : new Date(telephoneNumberDetails?.purchasedTimestampMs).toLocaleString()}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Location</Label>
                          <p>{formatTNLocation(telephoneNumberDetails)}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Cost</Label>
                          <p>
                            {!isNullOrUndefined(telephoneNumberDetails?.costFormatted) && telephoneNumberDetails?.costFormatted >= 0 &&
                              <span>${telephoneNumberDetails?.costFormatted}/month</span>
                            }

                            {isNullOrUndefined(telephoneNumberDetails?.costFormatted) &&
                              <span>N/A</span>
                            }
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Capabilities</Label>
                          <p>
                            {telephoneNumberDetails?.capabilities['voice'] &&
                              <i className="uil-phone-alt" title="Voice"></i>
                            }
                            &nbsp;
                            {telephoneNumberDetails?.capabilities['sms'] &&
                              <i className="uil-comment-alt-message" title="SMS"></i>
                            }
                            &nbsp;
                            {telephoneNumberDetails?.capabilities['mms'] &&
                              <i className="uil-comment-alt-image" title="MMS"></i>
                            }
                          </p>
                        </Col>
                        <Col md={8}>
                          <Label className="form-label">Campaign ID</Label>
                          <p>
                            {!isNullOrUndefined(telephoneNumberDetails?.campaignId) &&
                              <Button style={{ padding: "0" }} color="link" className="waves-effect" onClick={() => onCampaignDetailsClick(telephoneNumberDetails?.campaignId)} title="Go to campaign details">{telephoneNumberDetails?.campaignId}</Button>
                            }
                            {isNullOrUndefined(telephoneNumberDetails?.campaignId) &&
                              <span>-</span>
                            }
                          </p>
                        </Col>
                      </Row>
                    </>
                  }
                  <Row className="mb-3">
                    <div className="d-flex flex-wrap gap-3 mt-3">
                      <Button
                        type="reset"
                        color="danger"
                        outline
                        className="w-md"
                        onClick={onDeleteNumberClick}
                      >
                        Release
                      </Button>
                      <Button
                        color="link"
                        className="waves-effect"
                        style={{ padding: "0" }}
                        onClick={onBackClick}
                      >
                        &lt;&lt; Go To All Telephone Numbers
                      </Button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

NumberDetails.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(NumberDetails)));