import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getCampaigns
} from "../../store/actions";

import '../datatables.scss';


const Campaigns = () => {

  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const campaignsList = useSelector((state) => state.Csp.campaignsList);
  const loading = useSelector((state) => state.Csp.loading);
  const apiError = useSelector((state) => state.Csp.error);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [redirectToDetailsCampaignId, setRedirectToDetailsCampaignId] = useState(''); 
  const [redirectToDetailsBrandId, setRedirectToDetailsBrandId] = useState(''); 

  const redirectStatus = new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getCampaigns(currentProjectId));
    }
  }, [dispatch, currentProjectId, redirectStatus]);

  useEffect(() => {

    if (redirectStatus === 'delete-campaign-succeeded') {
      setSuccessMessage("Campaign was successfully deleted");
    }

    if (redirectStatus === 'update-campaign-succeeded') {
      setSuccessMessage("Campaign was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }
  }, [redirectStatus]);

  const truncateCampaignDescription = (desc) => {
    const maxDescLength = 30;
    if (!desc) {
      return "";
    }

    if (desc.length > maxDescLength) {
      return desc.substring(0, maxDescLength - 1) + '...';
    }

    return desc;
  }

  const onBrandDetailsClick = (brandId) => {
    setRedirectToDetailsBrandId(brandId); // show the details brand form for a specific brandId
  }

  const onCampaignDetailsClick = (campaignId) => {
    setRedirectToDetailsCampaignId(campaignId); // show the details campaign form for a specific campaignId
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Campaign Registry" breadcrumbItem="Project Campaigns" />
          <Card>
            <CardBody>
              {redirectToDetailsBrandId && <Redirect push to={`/projects/${currentProjectId}/csp/brands/${redirectToDetailsBrandId}`} />}
              {redirectToDetailsCampaignId && <Redirect push to={`/projects/${currentProjectId}/csp/campaigns/${redirectToDetailsCampaignId}`} />}
              {loading && <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              }
              {!loading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
              {!loading && apiError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
              {!loading &&
                <div className="actions clearfix">
                  <ul>
                    <li
                    >
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md"
                        onClick={() => alert('not_implemented')}
                      >
                        Register Campaign
                      </Button>
                    </li>
                  </ul>
                </div>
              }
              {!loading &&
                <>
                  <Row>
                    <Col className="col-12">
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Campaign ID</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Brand</th>
                            <th>Created Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(!campaignsList || !Array.isArray(campaignsList) || campaignsList.length === 0) && <tr><td colSpan={6} className="text-center">No campaigns registered yet</td></tr>}
                          {
                            campaignsList && Array.isArray(campaignsList) && campaignsList.length > 0 && campaignsList.map((c, i) => <tr key={i} className="align-middle">
                              <td style={{ width: "10%" }}>
                                <i className="uil-trash-alt" title="Deregister this campaign" role="button" onClick={() => alert('not_implemented')}></i>
                              </td>
                              <td><Button style={{ padding: "0" }} color="link" className="waves-effect" onClick={() => onCampaignDetailsClick(c.campaignId)} title="Go to campaign details">{c.campaignId}</Button></td>
                              <td>{truncateCampaignDescription(c.description)}</td>
                              <td>not_implemented</td>
                              <td><Button style={{ padding: "0" }} color="link" className="waves-effect" onClick={() => onBrandDetailsClick(c.brandId)} title="Go to brand details">{c.brandName}</Button></td>
                              <td>{!c.createdTimestampMs ? '' : new Date(c.createdTimestampMs).toLocaleString()}</td>
                            </tr>)
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Campaigns.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  Csp: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    csp: state.Csp,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Campaigns)));