import { get, post, put } from "../helpers/api_helper";
const _ = require('lodash');

class ProjectService {

  fetchProjectDetails(projectId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}`)
        .then(response => {
          const projectDetails = _.get(response, 'projectDetails', {});
          // sorting codecs by name
          if (projectDetails && _.isArray(projectDetails.codecs)) {
            projectDetails.codecs.sort((a, b) => (a.name > b.name) ? 1 : -1)
          }
          resolve(projectDetails);

        }).catch(error => {
          reject(error);
        })
    });

  }

  updateProject(projectId, name) {
    return new Promise((resolve, reject) => {
      put(
        `/projects/${projectId}`,
        {
          name
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  updateInboundMessageWebhook(projectId, url, headers) {
    return new Promise((resolve, reject) => {
      put(
        `/projects/${projectId}/inbound-message-webhook`,
        {
          url,
          headers
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  updateAutoCharge(projectId, thresholdAmount, topUpAmount, paymentMethodId) {
    return new Promise((resolve, reject) => {
      put(
        `/projects/${projectId}/auto-charge`,
        {
          thresholdAmount,
          topUpAmount,
          paymentMethodId
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  createProject(name) {
    return new Promise((resolve, reject) => {
      post(
        `/projects`,
        {
          name
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

}

export const projectService = new ProjectService();