import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Row, Col, Label, Alert } from "reactstrap";

const ProjectInfoCard = () => {

    const projectDetails = useSelector((state) => state.Project.projectDetails?.project);
    const isLoading = useSelector((state) => state.Project.loading);
    const apiError = useSelector((state) => state.Project.error);
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {!isLoading && apiError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
                    {!isLoading && projectDetails &&
                    <>
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">Project ID</Label>
                          <p>{projectDetails?.projectId}</p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">Project Base URL</Label>
                          <p>{projectDetails?.apiUriPrefix}</p>
                        </Col>
                      </Row>
                    </>
                    }
                </CardBody>
            </Card>

        </React.Fragment>
    );
};

export default ProjectInfoCard;