import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, Link, useParams, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table, Badge, Button } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getMessageTypeAndDirectionString, getBadgeCssClassName } from './utils';

import './messages.scss';

import {
  getMessagesForProject
} from "../../store/actions";

const MessagesList = () => {

  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const messagesList = useSelector((state) => state.Message.messagesList);
  const messagesLimit = useSelector((state) => state.Message.messagesLimit);
  const messagesOffset = useSelector((state) => state.Message.messagesOffset);
  const isLoading = useSelector((state) => state.Message.loading);
  const [redirectToDetailsMessageId, setRedirectToDetailsMessageId] = useState(''); // a flag to redirect to MessageDetails form (with particular messageId)

  const pageSize = messagesLimit || 20;

  const { direction } = useParams();

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getMessagesForProject(currentProjectId, direction, pageSize, 0));
    }
  }, [dispatch, currentProjectId, pageSize, direction]);

  const onNextPageClick = () => {
    if (messagesList && messagesList?.length >= pageSize) {
      dispatch(getMessagesForProject(currentProjectId, direction, pageSize, messagesOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (messagesOffset !== 0) {
      dispatch(getMessagesForProject(currentProjectId, direction, pageSize, messagesOffset - pageSize));
    }
  }

  const onGetMessageDetailsClick = (messageId) => {
    setRedirectToDetailsMessageId(messageId); // show the details message form for a specific messageId
  }

  return (
    <React.Fragment>
      <div className="page-content messages-list-table">
        <Container fluid>
          <Breadcrumbs title="SMS/MMS" breadcrumbItem={direction === "inbound" ? "Inbound Messages" : (direction === "outbound" ? "Outbound Messages" : "All Messages")} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      {isLoading && <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      }
                      {redirectToDetailsMessageId && <Redirect push to={`/projects/${currentProjectId}/messages/${redirectToDetailsMessageId}`} />}
                      {!isLoading &&
                        <Table className="table table-striped table-responsive">
                          <thead>
                            <tr>
                              <th>Message</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Sent Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(!messagesList || !Array.isArray(messagesList) || messagesList.length === 0) && <tr><td colSpan={5} className="text-center">No messages yet</td></tr>}
                            {
                              messagesList && Array.isArray(messagesList) && messagesList.length > 0 && messagesList.map((m, i) => <tr key={i} className="align-middle">
                                <td>
                                  <span>{getMessageTypeAndDirectionString(m)}</span><br />
                                  <Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetMessageDetailsClick(m.messageId)} title="Go to message details">{m.messageId}</Button>
                                </td>
                                <td>
                                  {m.from}
                                </td>
                                <td>{!m.to ? '' : m.to.join(", ")}</td>
                                <td>{!m.sentTimestampMs ? '' : new Date(m.sentTimestampMs).toLocaleString()}</td>
                                <td>
                                  <Badge className={getBadgeCssClassName(m)}>
                                    {m.status}
                                  </Badge>
                                </td>
                              </tr>)
                            }
                          </tbody>
                        </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {!isLoading &&
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                messagesOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={
                                (!messagesList || messagesList?.length === 0 || messagesList?.length < pageSize) ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

MessagesList.propTypes = {
  t: PropTypes.any,
  event: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    message: state.MEssage,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(MessagesList)));