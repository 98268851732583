/* PROJECT */
export const GET_PROJECT_DETAILS_REQUEST = "GET_PROJECT_DETAILS_REQUEST";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

export const UPDATE_INBOUND_MESSAGE_WEBHOOK_REQUEST = "UPDATE_INBOUND_MESSAGE_WEBHOOK_REQUEST";
export const UPDATE_INBOUND_MESSAGE_WEBHOOK_SUCCESS = "UPDATE_INBOUND_MESSAGE_WEBHOOK_SUCCESS";

export const UPDATE_AUTO_CHARGE_REQUEST = "UPDATE_AUTO_CHARGE_REQUEST";
export const UPDATE_AUTO_CHARGE_SUCCESS = "UPDATE_AUTO_CHARGE_SUCCESS";

export const PROJECT_API_ERROR = "PROJECT_API_ERROR";
