import visaLogo from "../../assets/images/billing/visa-logo.svg";
import mastercardLogo from "../../assets/images/billing/mastercard-logo.svg";
import amexLogo from "../../assets/images/billing/amex-logo.svg";
import discoverLogo from "../../assets/images/billing/discover-logo.svg";
import unionpayLogo from "../../assets/images/billing/unionpay-logo.svg";
import jcbLogo from "../../assets/images/billing/jcb-logo.svg";
import dinersLogo from "../../assets/images/billing/diners-logo.svg";
import eloLogo from "../../assets/images/billing/elo-logo.svg";

export const constructPaymentMethodBrandLogo = (brand) => {
  if (!brand) return <></>;

  if (brand.toUpperCase() === "VISA") {
    return <img src={visaLogo} alt="VISA" className="payment-method-brand-logo" />;
  }

  if (brand.toUpperCase() === "MASTERCARD") {
    return <img src={mastercardLogo} alt="MASTERCARD" className="payment-method-brand-logo" />;
  }

  if (brand.toUpperCase() === "AMEX") {
    return <img src={amexLogo} alt="AMEX" className="payment-method-brand-logo" />;
  }

  if (brand.toUpperCase() === "DISCOVER") {
    return <img src={discoverLogo} alt="DISCOVER" className="payment-method-brand-logo" />;
  }

  if (brand.toUpperCase() === "UNIONPAY") {
    return <img src={unionpayLogo} alt="UNIONPAY" className="payment-method-brand-logo" />;
  }

  if (brand.toUpperCase() === "JCB") {
    return <img src={jcbLogo} alt="JCB" className="payment-method-brand-logo" />;
  }

  if (brand.toUpperCase() === "DINERS") {
    return <img src={dinersLogo} alt="DINERS" className="payment-method-brand-logo" />;
  }

  if (brand.toUpperCase() === "ELO") {
    return <img src={eloLogo} alt="ELO" className="payment-method-brand-logo" />;
  }

  return brand.toUpperCase();
}