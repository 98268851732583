import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from "react";

import { Route, Switch } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import {
  authUserChanged,
  logoutUser
} from "./store/actions";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import { initFirebaseBackend, getFirebaseBackend } from "./helpers/firebase_helper";

console.log(`APP_ENV=${process.env.REACT_APP_ENV}`);

const AuthTokenExpirationHours = process?.env?.REACT_APP_AUTH_TOKEN_EXPIRATION_HOURS ?? 3; // a fallback to 3hrs

// Import Fake Backend
// import  from "./helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// init firebase backend
initFirebaseBackend(firebaseConfig);

const App = props => {

  const dispatch = useDispatch();

  // this is needed to prevent multiple onAuthStateChanged calls. It's still called once every URL changes
  const notifyAuthUserChanged = useCallback((user) => {
    if (user) {
      const lastSignInTime = new Date(user.metadata.lastSignInTime);
      const lastSignInTimeTimeStamp = Math.round(lastSignInTime.getTime() / 1000);
      const yesterdayTimeStamp = Math.round(new Date().getTime() / 1000) - (AuthTokenExpirationHours * 3600);
      if (lastSignInTimeTimeStamp < yesterdayTimeStamp) { // token should be issued not more than a day ago. if it's older - we redirect to the Login page with a return link.
        let {pathname, search} = window.location; // we should not use useLocation to prevent function recreation
        let returnUrl = `${pathname ?? ''}${search ?? ''}`;

        if (pathname === "/login") {
          // we are already on the login page. we do not need to append the search part. Otherwise we receive /login?r=/login
          returnUrl = undefined
        }

        dispatch(logoutUser(null, returnUrl)); //props?.logoutUser(returnUrl); CANNOT USE props to avoid constant function recreation
        return;
      }
    }

    dispatch(authUserChanged(user)); //  props?.onAuthUserChanged(user); CANNOT USE props to avoid constant function recreation

  }, [dispatch]);

  useEffect(() => {
    getFirebaseBackend().onIdTokenChanged(function (user) {
      notifyAuthUserChanged(user);
    });
  }, [notifyAuthUserChanged]);

  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }


  const Layout = getLayout();
  return (
    <React.Fragment>
      <Switch>
        <Route>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Route>
      </Switch>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, {})(App);