import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthAction from "../pages/Authentication/AuthAction"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"


////// Corelink pages

import Tokens from "../pages/API/Tokens";
import AddToken from "../pages/API/AddToken";
import TokenDetails from "../pages/API/TokenDetails";
import ProjectBalance from "../pages/Billing/ProjectBalance";
import TopUpBalance from "../pages/Billing/TopUpBalance";
import PaymentMethodsTable from "../pages/Billing/PaymentMethodsTable";
import AddPaymentMethod from "../pages/Billing/AddPaymentMethod";
import PaymentsHistory from "../pages/Billing/PaymentsHistory";
import ProfileDetails from "../pages/Profile/ProfileDetails";
import InboundMessageWebHook from "../pages/Settings/InboundMessageWebHook";
import NumbersList from "../pages/Numbers/NumbersList";
import SearchInventory from "../pages/Numbers/SearchInventory";
import NumberDetails from "../pages/Numbers/NumberDetails";
import EventsList from "../pages/Events/EventsList";
import MessagesList from "../pages/Messages/MessagesList";
import MessageDetails from "../pages/Messages/MessageDetails";
import ProjectDetails from "../pages/Settings/ProjectDetails";
import Brands from "../pages/Csp/Brands";
import BrandDetails from "../pages/Csp/BrandDetails";
import Campaigns from "../pages/Csp/Campaigns";
import CampaignDetails from "../pages/Csp/CampaignDetails";
import Users from "../pages/Settings/Users";
import InviteDetails from "../pages/InviteDetails";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  //// Corelink routes
  { path: "/projects/:projectId/dashboard", component: Dashboard },
  { path: "/projects/:projectId/api/tokens", component: Tokens },
  { path: "/projects/:projectId/api/tokens/add", component: AddToken },
  { path: "/projects/:projectId/api/tokens/:tokenId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: TokenDetails }, // matching only GUID. Otherwise "/tokens/add" will be matched as well
  { path: "/projects/:projectId/billing/balance", component: ProjectBalance },
  { path: "/projects/:projectId/billing/balance/top-up", component: TopUpBalance },
  { path: "/projects/:projectId/billing/payment-methods", component: PaymentMethodsTable },
  { path: "/projects/:projectId/billing/payment-methods/add", component: AddPaymentMethod },
  { path: "/projects/:projectId/billing/payments-history", component: PaymentsHistory },
  { path: "/projects/:projectId/settings/project-details", component: ProjectDetails },
  { path: "/projects/:projectId/settings/inbound-message-webhook", component: InboundMessageWebHook },
  { path: "/projects/:projectId/settings/users", component: Users },
  { path: "/projects/:projectId/numbers", component: NumbersList },
  { path: "/projects/:projectId/numbers/:telephoneNumberId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: NumberDetails }, // matching only GUID. Otherwise "/numbers/search" will be matched as well
  { path: "/projects/:projectId/numbers/search", component: SearchInventory },
  { path: "/projects/:projectId/csp/brands", component: Brands },
  { path: "/projects/:projectId/csp/brands/:brandId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: BrandDetails }, // matching only GUID. Otherwise "/brands/add" will be matched as well
  { path: "/projects/:projectId/csp/campaigns", component: Campaigns },
  { path: "/projects/:projectId/csp/campaigns/:campaignId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: CampaignDetails }, // matching only GUID. Otherwise "/campaigns/add" will be matched as well
  { path: "/projects/:projectId/events", component: EventsList },
  { path: "/projects/:projectId/messages", component: MessagesList },
  { path: "/projects/:projectId/messages/:direction(outbound)", component: MessagesList }, /* :direction(outbound) guarantees only "outbound" as a value for direction param. It's a regexp with one value equal "outbound" */
  { path: "/projects/:projectId/messages/:direction(inbound)", component: MessagesList }, /* :direction(inbound) guarantees only "inbound" as a value for direction param. It's a regexp with one value equal "inbound" */
  { path: "/projects/:projectId/messages/:messageId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: MessageDetails }, // matching only GUID. Otherwise "/message/outbound" will be matched as well
  { path: "/profile", component: ProfileDetails },

  { path: "/invites/:inviteId", component: InviteDetails },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />
  }, 
];

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth/action", component: AuthAction },


  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
]

export { userRoutes, authRoutes }