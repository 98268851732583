import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { tokenService } from "../../services";

const AddToken = () => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [redirectToTokensList, setRedirectToTokensList] = useState(false); // a flag to redirect back

  const [generatedToken, setGeneratedToken] = useState(null); // to the generated token 
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const onCancelAddTokenClick = () => {
    setRedirectToTokensList(true);
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      tokenName: "",
      tokenScopes: [],
    },
    validationSchema: Yup.object({
      tokenName: Yup.string().required("Please enter token name"),
      tokenScopes: Yup.array().min(1, "Please select at least one scope")
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        let response = await tokenService.generateToken(currentProjectId, values.tokenName, values.tokenScopes);
        setGeneratedToken(response.createdToken);
      } catch (err) {
        console.log(err);
        setErrorMessage("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      {redirectToTokensList &&
        <Redirect push to={`/projects/${currentProjectId}/api/tokens`} />
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="API" breadcrumbItem="Generate Token" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {isLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!isLoading && errorMessage ? <Alert color="danger" role="alert">{errorMessage}</Alert> : null}
                  {!isLoading && !generatedToken &&
                    <>
                      <Form
                        className="form-group top-up-balance"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Label className="form-label">Token Name</Label>
                          <InputGroup>
                            <Input
                              name="tokenName"
                              placeholder="Enter Token Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.tokenName || ""}
                              invalid={
                                validation.touched.tokenName && validation.errors.tokenName ? true : false
                              }
                            />
                            {validation.touched.tokenName && validation.errors.tokenName ? (
                              <FormFeedback type="invalid">{validation.errors.tokenName}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Scopes</Label>
                          {/* 
            Multiple checkboxes with the same name attribute, but different
            value attributes will be considered a "checkbox group". Formik will automagically
            bind the checked values to a single array for your benefit. All the add and remove
            logic will be taken care of for you. See https://formik.org/docs/examples/checkboxes
                        */}

                          <div className="vstack gap-2">
                            <label className="form-check-label form-check">
                              <Input
                                name="tokenScopes"
                                type="checkbox"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value="voice"
                              />
                              Voice
                            </label>

                            <label className="form-check-label form-check">
                              <Input
                                name="tokenScopes"
                                type="checkbox"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value="messaging"
                              />
                              Messaging
                            </label>

                            <label className="form-check-label form-check">
                              <Input
                                name="tokenScopes"
                                type="checkbox"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value="lookup"
                              />
                              Lookup
                            </label>

                            <label className="form-check-label form-check">
                              <Input
                                name="tokenScopes"
                                type="checkbox"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value="numbers"
                              />
                              Numbers
                            </label>

                            {validation.errors.tokenScopes ? ( /* FormFeedback is not working for <checkboxes> for some reason. <span className="is-invalid"></span> is needed to make FormFeedback visible. See CSS:
                          .is-invalid ~ .invalid-feedback,
                          .is-invalid ~ .invalid-tooltip {
                          display: block; } in bootstrap
                          */
                              <>
                                <span className="is-invalid"></span>
                                <FormFeedback type="invalid">{validation.errors.tokenScopes}</FormFeedback>
                              </>
                            ) : null}
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Generate
                            </Button>
                            <Button
                              type="reset"
                              color="link"
                              className="waves-effect"
                              style={{ padding: "0" }}
                              onClick={onCancelAddTokenClick}
                            >
                              &lt; &lt; Back To Tokens List
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                  {!isLoading && generatedToken &&
                    <>
                      <Row className="mb-3">
                        <Alert color="success" role="alert">Successfully generated a new token: <br />
                          <strong>{generatedToken.tokenValue}</strong>
                        </Alert>
                      </Row>
                      <Row className="mb-3">
                        <div className="d-flex flex-wrap gap-3 mt-3">
                          <Button
                            type="reset"
                            color="link"
                            className="waves-effect"
                            style={{ padding: "0" }} 
                            onClick={onCancelAddTokenClick}
                          >
                            &lt; &lt; Back To Tokens List
                          </Button>
                        </div>
                      </Row>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddToken.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  tokens: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    token: state.Token,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AddToken)));