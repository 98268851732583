import { get, post, put, del } from "../helpers/api_helper";
const _ = require('lodash');

class AuthUserService {

  fetchProjects() {
    return new Promise((resolve, reject) => {
      get(`/projects`)
        .then(response => {
          const projects = _.get(response, 'projects', []);
          resolve(projects);

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchUsersByProjectId(projectId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/users`)
        .then(response => {
          const users = _.get(response, 'users', []);
          resolve(users);

        }).catch(error => {
          reject(error);
        })
    });

  }

  inviteUser(projectId, email) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/invites`,
        {
          email
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchInviteDetails(inviteId) {
    return new Promise((resolve, reject) => {
      get(`/invites/${inviteId}`)
        .then(response => {
          const inviteDetails = _.get(response, 'invite', {});
          resolve(inviteDetails);

        }).catch(error => {
          reject(error);
        })
    });

  }

  acceptInvite(inviteId) {
    return new Promise((resolve, reject) => {
      put(`/invites/${inviteId}`)
        .then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  deleteUserFromProject(projectId, userId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/users/${userId}`)
        .then( () => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

}

export const authUserService = new AuthUserService();