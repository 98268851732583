import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";

import { EventDescription } from './EventDescription';

import { isNullOrUndefined } from '../../helpers/utils';

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import './events.scss';

import {
  getEventsForProject
} from "../../store/actions";

const EventsList = () => {

  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const eventsList = useSelector((state) => state.Event.eventsList);
  const eventsLimit = useSelector((state) => state.Event.eventsLimit);
  const eventsOffset = useSelector((state) => state.Event.eventsOffset);
  const isLoading = useSelector((state) => state.Event.loading);

  const pageSize = eventsLimit || 20;

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getEventsForProject(currentProjectId, pageSize, 0));
    }
  }, [dispatch, currentProjectId, pageSize]);

  const onNextPageClick = () => {
    if (eventsList && eventsList?.length >= pageSize) {
      dispatch(getEventsForProject(currentProjectId, pageSize, eventsOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (eventsOffset !== 0) {
      dispatch(getEventsForProject(currentProjectId, pageSize, eventsOffset - pageSize));
    }
  }


  return (
    <React.Fragment>
      <div className="page-content events-list-table">
        <Container fluid>
          <Breadcrumbs title="Events" breadcrumbItem="Project Events" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      {isLoading && <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      }
                      {!isLoading &&
                        <Table className="table table-striped table-responsive">
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Balance Change</th>
                              <th>User</th>
                              <th>Event Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(!eventsList || !Array.isArray(eventsList) || eventsList.length === 0) && <tr><td colSpan={4} className="text-center">No events yet</td></tr>}
                            {
                              eventsList && Array.isArray(eventsList) && eventsList.length > 0 && eventsList.map((e, i) => <tr key={i} className="align-middle">
                                <td>
                                  <EventDescription event={e} />
                                </td>
                                <td>
                                  {!isNullOrUndefined(e.balanceChangeAmount) && e.balanceChangeAmount >= 0 &&
                                    <span>${e.balanceChangeAmountFormatted}</span>
                                  }
                                  {!isNullOrUndefined(e.balanceChangeAmount) && e.balanceChangeAmount < 0 &&
                                    <span>-${e.balanceChangeAmountFormatted.substring(1)}</span>
                                  }
                                  {isNullOrUndefined(e.balanceChangeAmount) && 
                                    <span>N/A</span>
                                  }
                                </td>
                                <td>{e.createdByUserEmail ?? 'system'}</td>
                                <td>{!e.createdTimestampMs ? '' : new Date(e.createdTimestampMs).toLocaleString()}</td>
                              </tr>)
                            }
                          </tbody>
                        </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {!isLoading &&
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                eventsOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={
                                (!eventsList || eventsList?.length === 0 || eventsList?.length < pageSize) ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EventsList.propTypes = {
  t: PropTypes.any,
  event: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    event: state.Event,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(EventsList)));