import React from "react";
import { withRouter } from "react-router-dom";
import SetPassword from "./SetPassword";

const AuthAction = props => {

  // Retrieve the "mode" query parameter
  const authMode = new URLSearchParams(window?.location?.search).get(
    'mode'
  );

  return (
    <>{/* TODO: add more actions here */}
      {authMode === 'resetPassword' &&
        <SetPassword />
      }
    </>
  );
}

export default withRouter(AuthAction);
