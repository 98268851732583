import { all, fork, call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  GET_NUMBERS_FOR_PROJECT_REQUEST,
  GET_NUMBER_DETAILS_REQUEST,
  SEARCH_INVENTORY_FOR_PROJECT_REQUEST,
  DELETE_NUMBER_REQUEST
} from "./actionTypes";
import {
  getNumbersForProjectSuccess,
  getNumberDetailsSuccess,
  searchInventoryForProjectSuccess,
  deleteNumberSuccess,
  apiError
} from "./actions";

//Include Both Helper File with needed methods
import { telephoneNumberService } from "../../services";

function* getTelephoneNumbers({ payload: {projectId, limit, offset} }) {
  try {
    const response = yield call(
      telephoneNumberService.fetchTelephoneNumbers,
      projectId,
      limit,
      offset
    )
    yield put(getNumbersForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* getNumberDetails({ payload: { projectId, telephoneNumberId } }) {
  try {
    const response = yield call(
      telephoneNumberService.fetchTelephoneNumberDetails,
      projectId,
      telephoneNumberId
    )
    yield put(getNumberDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* searchInventory({ payload: {projectId, telephoneNumberType, maxResults, countryCode, lata, regionName, locationName, containsFilter} }) {
  try {
    const response = yield call(
      telephoneNumberService.searchInventory,
      projectId,
      telephoneNumberType,
      maxResults,
      countryCode,
      lata,
      regionName,
      locationName,
      containsFilter
    )
    yield put(searchInventoryForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* deleteNumber({ payload: { projectId, telephoneNumberId, callback } }) {
  try {
    const response = yield call(
      telephoneNumberService.releaseTelephoneNumber,
      projectId,
      telephoneNumberId
    )
    yield put(deleteNumberSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* watchTelephoneNumbersRequests() {
  yield takeEvery(GET_NUMBERS_FOR_PROJECT_REQUEST, getTelephoneNumbers)
}

function* watchGetNumberDetailsRequests() {
  yield takeEvery(GET_NUMBER_DETAILS_REQUEST, getNumberDetails)
}

function* watchSearchInventoryRequests() {
  yield takeLatest(SEARCH_INVENTORY_FOR_PROJECT_REQUEST, searchInventory)
}

function* watchDeleteNumberRequests() {
  yield takeEvery(DELETE_NUMBER_REQUEST, deleteNumber)
}

function* numbersSaga() {
  yield all([
    fork(watchTelephoneNumbersRequests),
    fork(watchGetNumberDetailsRequests),
    fork(watchSearchInventoryRequests),
    fork(watchDeleteNumberRequests),
  ]);
}

export default numbersSaga;
