import {
  GET_MESSAGES_FOR_PROJECT_REQUEST,
  GET_MESSAGES_FOR_PROJECT_SUCCESS,
  GET_MESSAGE_DETAILS_REQUEST,
  GET_MESSAGE_DETAILS_SUCCESS,
  MESSAGE_API_ERROR
} from "./actionTypes";

export const getMessagesForProject = (projectId, direction, limit, offset) => {
  return {
    type: GET_MESSAGES_FOR_PROJECT_REQUEST,
    payload: { projectId, direction, limit, offset }
  }
};

export const getMessagesForProjectSuccess = ({ messages: messagesList, offset: messagesOffset, limit: messagesLimit }) => {
  return {
    type: GET_MESSAGES_FOR_PROJECT_SUCCESS,
    payload: { messagesList, messagesOffset, messagesLimit }
  }
};

export const getMessageDetails = (projectId, messageId) => {
  return {
    type: GET_MESSAGE_DETAILS_REQUEST,
    payload: { projectId, messageId }
  }
};

export const getMessageDetailsSuccess = message => {
  return {
    type: GET_MESSAGE_DETAILS_SUCCESS,
    payload: message
  }
};

export const apiError = error => {
  return {
    type: MESSAGE_API_ERROR,
    payload: error,
  }
}