// @flow
import {
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_INBOUND_MESSAGE_WEBHOOK_REQUEST,
  UPDATE_INBOUND_MESSAGE_WEBHOOK_SUCCESS,
  UPDATE_AUTO_CHARGE_REQUEST,
  UPDATE_AUTO_CHARGE_SUCCESS,
  PROJECT_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,
  projectDetails: undefined
};

const Project = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_PROJECT_DETAILS_SUCCESS:
      let originalWebHook = action.payload?.project?.inboundMessageWebHook;
      if (originalWebHook && originalWebHook.headers) {
        let headers = {};
        let headersKeys = Object.keys(originalWebHook.headers);
        if (headersKeys.length > 0) {
          for (var i = 0; i < headersKeys.length; i++) {
            headers[`headerName${i + 1}`] = headersKeys[i];
            headers[`headerValue${i + 1}`] = originalWebHook.headers[headersKeys[i]];
          }
        }

        action.payload.project.inboundMessageWebHook.headersHelperObject = headers;
      }

      return {
        ...state,
        loading: false,
        projectDetails: action.payload,
      }

    case UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_INBOUND_MESSAGE_WEBHOOK_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case UPDATE_INBOUND_MESSAGE_WEBHOOK_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_AUTO_CHARGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case UPDATE_AUTO_CHARGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROJECT_API_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Project;
