import { all, fork, call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  GET_MESSAGES_FOR_PROJECT_REQUEST,
  GET_MESSAGE_DETAILS_REQUEST,
} from "./actionTypes";
import {
  getMessagesForProjectSuccess,
  getMessageDetailsSuccess,
  apiError
} from "./actions";

//Include Both Helper File with needed methods
import { messageService } from "../../services";

function* getMessages({ payload: {projectId, direction, limit, offset} }) {
  try {
    const response = yield call(
      messageService.fetchMessages,
      projectId,
      direction,
      limit,
      offset
    )
    yield put(getMessagesForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* getMessageDetails({ payload: { projectId, messageId } }) {
  try {
    const response = yield call(
      messageService.fetchMessageDetails,
      projectId,
      messageId
    )
    yield put(getMessageDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* watchMessagesRequests() {
  yield takeEvery(GET_MESSAGES_FOR_PROJECT_REQUEST, getMessages)
}

function* watchGetMessageDetailsRequests() {
  yield takeEvery(GET_MESSAGE_DETAILS_REQUEST, getMessageDetails)
}

function* messageSaga() {
  yield all([
    fork(watchMessagesRequests),
    fork(watchGetMessageDetailsRequests),
  ]);
}

export default messageSaga;
