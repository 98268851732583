import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card, CardTitle, CardBody, Button, Alert } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { connect, useSelector } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

const ProjectBalanceCard = () => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const projectBalance = useSelector((state) => state.Project.projectDetails?.project?.balanceAmountFormatted);
  const projectDetailsLoading = useSelector((state) => state.Project.loading);

  const [redirectToTopUpBalance, setRedirectToTopUpBalance] = useState(false); // a flag to redirect to TopUpBalance form
  const [billingSuccessMessage, setBillingSuccessMessage] = useState(''); // to show a success message when we added a payment method

  useEffect(() => {
    const redirectStatus = new URLSearchParams(window?.location?.search).get(
      'redirect_status'
    );

    if (redirectStatus === 'top-up-balance-succeeded') {
      setBillingSuccessMessage("Successfully topped up the balance.");
    }
  }, []);

  const onTopUpBalanceClick = () => {
    //dispatch(bApiError('')); // clear the error message first
    setRedirectToTopUpBalance(true); // show the add payment method form
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Current Amount</CardTitle>
          {billingSuccessMessage ? <Alert color="success" role="alert">{billingSuccessMessage}</Alert> : null}
          {projectDetailsLoading && <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          }
          {redirectToTopUpBalance && <Redirect push to={`/projects/${currentProjectId}/billing/balance/top-up`} />}
          {!projectDetailsLoading &&
            <div>
              <h1>${projectBalance}</h1>
              <div className="d-flex flex-wrap gap-3 mt-3">
                <Button
                  type="submit"
                  color="primary"
                  className="w-md"
                  onClick={onTopUpBalanceClick}
                >
                  Top Up Balance
                </Button>
              </div>
            </div>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ProjectBalanceCard.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ProjectBalanceCard)));