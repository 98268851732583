import axios from "axios";
import {getFirebaseBackend} from './firebase_helper';
import config from '../config';

//apply base url for axios
const API_URL = config.API_URL;

console.log(`API_URL=${API_URL}`);

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use((request) => {
  return getFirebaseBackend().getIdToken().then((idToken) => {
    // request.headers['Authorization'] = idToken ? `Bearer ${idToken}` : ''; we do not use "Bearer" keyword
    request.headers['Authorization'] = idToken;
    return Promise.resolve(request);
  }).catch(error => {
    return Promise.reject(error);
  })
}, (error) => {
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
