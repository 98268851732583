import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Redirect, withRouter, useParams, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getTokenDetails,
  updateToken,
  deleteToken,
  getTokensForProject
} from "../../store/actions";

const TokenDetails = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  // getting tokenId fromURL
  const { tokenId: currentTokenId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const tokenDetails = useSelector((state) => state.Token.tokenDetails);
  const scopes = useSelector((state) => state.Token.tokenDetails?.scopes);

  const [redirectToTokensList, setRedirectToTokensList] = useState(false); // a flag to redirect back
  const [isTokenValueHidden, setIsTokenValueHidden] = useState(true); // a flag to show/hide the token value

  const isLoading = useSelector((state) => state.Token.loading);
  const tokensAPIError = useSelector((state) => state.Token.error);

  useEffect(() => {
    if (currentProjectId && currentTokenId) {
      dispatch(getTokenDetails(currentProjectId, currentTokenId));
    }
  }, [dispatch, currentProjectId, currentTokenId]);

  const onCancelUpdateTokenClick = () => {
    setRedirectToTokensList(true);
  }

  const onDeleteTokenClick = () => {
    if (window.confirm("Are you sure you want to delete this token?") === true) {
      dispatch(deleteToken(currentProjectId, currentTokenId, () => {
        dispatch(getTokensForProject(currentProjectId));
        history.push(`/projects/${currentProjectId}/api/tokens?redirect_status=delete-token-succeeded`);
      }))
    }
  }

  const toggleTokenScope = (tokenScopeName) => {
    if (!scopes) {
      return;
    }

    if (scopes.includes(tokenScopeName)) {
      // removing the scope from the array:
      scopes.splice(scopes.indexOf(tokenScopeName), 1);
    } else {
      // else adding the scope to the array
      scopes.push(tokenScopeName);
    }

    validation.setFieldValue("tokenScopes", scopes);

  }


  // formik cannot handle initial values of checkboxes (they are always unchecked) for some reason. so the workaround is to handle them manually
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      tokenName: tokenDetails?.name,
      tokenScopes: scopes,
    },
    validationSchema: Yup.object({
      tokenName: Yup.string().required("Please enter token name"),
      tokenScopes: Yup.array().min(1, "Please select at least one scope")
    }),
    onSubmit: async (values) => {
      dispatch(updateToken(currentProjectId, currentTokenId, values.tokenName, values.tokenScopes, () => history.push(`/projects/${currentProjectId}/api/tokens?redirect_status=update-token-succeeded`)))
    }
  });

  return (
    <React.Fragment>
      {redirectToTokensList &&
        <Redirect push to={`/projects/${currentProjectId}/api/tokens`} />
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="API" breadcrumbItem="Token Details" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {isLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!isLoading && tokensAPIError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
                  {!isLoading && tokenDetails && currentTokenId &&
                    <>
                      <Row className="mb-3">
                        <Label className="form-label">Token</Label>
                        {!isTokenValueHidden &&
                          <p>{tokenDetails?.tokenValue}</p>
                        }
                        {isTokenValueHidden &&
                          <p>*************** <i className="uil-eye" title="Show value" role="button" onClick={() => setIsTokenValueHidden(false)}></i></p>
                        }
                      </Row>
                      <Form
                        className="form-group top-up-balance"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Label className="form-label">Name</Label>
                          <InputGroup>
                            <Input
                              name="tokenName"
                              placeholder="Enter Token Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.tokenName || ""}
                              invalid={
                                validation.touched.tokenName && validation.errors.tokenName ? true : false
                              }
                            />
                            {validation.touched.tokenName && validation.errors.tokenName ? (
                              <FormFeedback type="invalid">{validation.errors.tokenName}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Scopes</Label>
                          {/* 
            Multiple checkboxes with the same name attribute, but different
            value attributes will be considered a "checkbox group". Formik will automagically
            bind the checked values to a single array for your benefit. All the add and remove
            logic will be taken care of for you. See https://formik.org/docs/examples/checkboxes
                        */}

                          <div className="vstack gap-2">
                            <label className="form-check-label form-check">
                              <Input
                                name="scopes1"
                                type="checkbox"
                                onChange={() => toggleTokenScope("voice")}
                                onBlur={validation.handleBlur}
                                defaultChecked={scopes?.includes("voice")}
                                value="voice"
                              />
                              Voice
                            </label>

                            <label className="form-check-label form-check">
                              <Input
                                name="scopes1"
                                type="checkbox"
                                onChange={() => toggleTokenScope("messaging")}
                                onBlur={validation.handleBlur}
                                defaultChecked={scopes?.includes("messaging")}
                                value="messaging"
                              />
                              Messaging
                            </label>

                            <label className="form-check-label form-check">
                              <Input
                                name="scopes1"
                                type="checkbox"
                                onChange={() => toggleTokenScope("lookup")}
                                onBlur={validation.handleBlur}
                                defaultChecked={scopes?.includes("lookup")}
                                value="lookup"
                              />
                              Lookup
                            </label>

                            <label className="form-check-label form-check">
                              <Input
                                name="scopes1"
                                type="checkbox"
                                onChange={() => toggleTokenScope("numbers")}
                                onBlur={validation.handleBlur}
                                defaultChecked={scopes?.includes("numbers")}
                                value="numbers"
                              />
                              Numbers
                            </label>

                            {validation.errors.tokenScopes ? ( /* FormFeedback is not working for <checkboxes> for some reason. <span className="is-invalid"></span> is needed to make FormFeedback visible. See CSS:
                          .is-invalid ~ .invalid-feedback,
                          .is-invalid ~ .invalid-tooltip {
                          display: block; } in bootstrap
                          */
                              <>
                                <span className="is-invalid"></span>
                                <FormFeedback type="invalid">{validation.errors.tokenScopes}</FormFeedback>
                              </>
                            ) : null}
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                            <Button
                              type="reset"
                              color="danger"
                              outline
                              className="w-md"
                              onClick={onDeleteTokenClick}
                            >
                              Delete
                            </Button>
                            <Button
                              type="reset"
                              color="link"
                              className="waves-effect"
                              style={{ padding: "0" }}
                              onClick={onCancelUpdateTokenClick}
                            >
                              &lt; &lt; Back To Tokens List
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TokenDetails.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  tokens: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    token: state.Token,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(TokenDetails)));