import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Card, CardTitle, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Alert } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { constructPaymentMethodBrandLogo } from "./common";

import {
  getPaymentMethodsForProject,
  updateAutoCharge,
  getProjectDetails
} from "../../store/actions";

const AutoReloadBalanceCard = () => {

  const dispatch = useDispatch();

  const projectAutoCharge = useSelector((state) => state.Project.projectDetails?.project?.autoRecharge)
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const paymentMethodsList = useSelector((state) => state.Billing.paymentMethodsList);
  const projectDetailsLoading = useSelector((state) => state.Project.loading);

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const apiError = useSelector((state) => state.Project.error);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getPaymentMethodsForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId]);

  const onAutoRechargeDisableClick = () => {
    dispatch(updateAutoCharge(currentProjectId, null, null, null, () => { dispatch(getProjectDetails(currentProjectId)); setSuccessMessage("Auto recharge was disabled"); }));
  }

  const onPaymentMethodChange = (newOption) => {
    validation.setFieldValue("paymentMethodId", newOption.value);
  }

  // constructs payment method name to the user
  const constructPaymentMethodName = (pm) => {
    if (!pm || !pm.card) {
      return '';
    }

    return <div>{constructPaymentMethodBrandLogo(pm?.card?.brand)} **** **** **** {pm?.card?.last4} (expires {pm?.card?.expMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 })}/{pm?.card?.expYear})</div>;
  }

  const constructPaymentMethodsOptionGroup = () => {
    let options = [];
    if (Array.isArray(paymentMethodsList)) {
      paymentMethodsList.forEach((val) => {
        options.push({
          "label": constructPaymentMethodName(val),
          "value": val?.paymentMethodId
        });
      })
    }

    return options;
  }

  const paymentMethodOptionGroup = constructPaymentMethodsOptionGroup();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      thresholdAmount: projectAutoCharge?.thresholdAmountFormatted,
      topUpAmount: projectAutoCharge?.topUpAmountFormatted,
      paymentMethodId: projectAutoCharge?.paymentMethodId,

    },
    validationSchema: Yup.object({
      thresholdAmount: Yup.number().integer("Please enter an integer value")
        .required("Please enter threshold amount"),
      topUpAmount: Yup.number()
        .integer("Please enter an integer value")
        .min(5, "Top up amount should be at least $5")
        .max(10000, "Top up amount should be less than $10000")
        .required("Please enter amount"),
      paymentMethodId: Yup.string().trim().required("Please select your payment method"),
    }),
    onSubmit: async (values) => {
      dispatch(updateAutoCharge(currentProjectId, Number(values.thresholdAmount), Number(values.topUpAmount), values.paymentMethodId, () => setSuccessMessage("Auto recharge settings were successfully updated")));
    }
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Automatic Recharge</CardTitle>
          {apiError ? <Alert color="danger">Something went wrong.</Alert> : null}
          {successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
          {projectDetailsLoading && <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          }
          {!projectDetailsLoading &&
            <>
              <p className="card-title-desc">Project balance will be automatically topped up when it reaches the threshold amount</p>
              <Form
                className="form-group automatic-recharge"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row className="mb-3">
                  <Label className="form-label">Threshold Amount</Label>
                  <InputGroup>
                    <div className="input-group-text">$</div>
                    <Input
                      name="thresholdAmount"
                      placeholder="Enter Threshold Amount"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.thresholdAmount || ""}
                      invalid={
                        validation.touched.thresholdAmount && validation.errors.thresholdAmount ? true : false
                      }
                    />
                    {validation.touched.thresholdAmount && validation.errors.thresholdAmount ? (
                      <FormFeedback type="invalid">{validation.errors.thresholdAmount}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">Top Up Amount</Label>
                  <InputGroup>
                    <div className="input-group-text">$</div>
                    <Input
                      name="topUpAmount"
                      placeholder="This amount will be added to your balance"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.topUpAmount || ""}
                      invalid={
                        validation.touched.topUpAmount && validation.errors.topUpAmount ? true : false
                      }
                    />
                    {validation.touched.topUpAmount && validation.errors.topUpAmount ? (
                      <FormFeedback type="invalid">{validation.errors.topUpAmount}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">Payment Method</Label>
                  {/* see https://github.com/JedWatson/react-select/issues/3263#issuecomment-604237097 fpr explanation. without next two attributes the dropdown is behind the card */}
                  <Select
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    isSearchable={false}
                    options={paymentMethodOptionGroup}
                    value={
                      paymentMethodOptionGroup.filter(option =>
                      option.value === validation.values.paymentMethodId)
                    }
                    onChange={onPaymentMethodChange}
                  />
                  {validation.touched.paymentMethodId && validation.errors.paymentMethodId ? ( /* FormFeedback is not working for <Select> for some reason. <span className="is-invalid"></span> is needed to make FormFeedback visible. See CSS:
                          .is-invalid ~ .invalid-feedback,
                          .is-invalid ~ .invalid-tooltip {
                          display: block; } in bootstrap
                          */
                    <>
                      <span className="is-invalid"></span>
                      <FormFeedback type="invalid">{validation.touched.paymentMethodId && validation.errors.paymentMethodId}</FormFeedback>
                    </>
                  ) : null}
                </Row>
                <Row className="mb-3">
                  <div className="d-flex flex-wrap gap-3 mt-3">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-md"
                    >
                      Update
                    </Button>
                    <Button
                      type="reset"
                      color="danger"
                      outline
                      className="w-md"
                      onClick={onAutoRechargeDisableClick}
                    >
                      Disable
                    </Button>
                  </div>
                </Row>
              </Form>
            </>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

AutoReloadBalanceCard.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AutoReloadBalanceCard)));