import {
  GET_AUTH_USER_PROJECTS_REQUEST,
  GET_AUTH_USER_PROJECTS_SUCCESS,
  AUTH_USER_CHANGED,
  CURRENT_PROJECT_ID_CHANGED,
  GET_AUTH_USERS_FOR_PROJECT_REQUEST,
  GET_AUTH_USERS_FOR_PROJECT_SUCCESS,
  GET_INVITE_DETAILS_REQUEST,
  GET_INVITE_DETAILS_SUCCESS,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_SUCCESS,
  DELETE_USER_FROM_PROJECT_REQUEST,
  DELETE_USER_FROM_PROJECT_SUCCESS,
  AUTH_USER_API_ERROR
} from "./actionTypes";

export const getAuthUserProjects = () => {
  return {
    type: GET_AUTH_USER_PROJECTS_REQUEST,
    payload: {}
  }
};

export const getAuthUserProjectsSuccess = projects => {
  return {
    type: GET_AUTH_USER_PROJECTS_SUCCESS,
    payload: projects
  }
};

export const getAuthUsersForProject = projectId => {
  return {
    type: GET_AUTH_USERS_FOR_PROJECT_REQUEST,
    payload: projectId
  }
};

export const getAuthUsersForProjectSuccess = users => {
  return {
    type: GET_AUTH_USERS_FOR_PROJECT_SUCCESS,
    payload: users
  }
};

export const authUserChanged = user => {
  return {
    type: AUTH_USER_CHANGED,
    payload: user
  }
};

export const onCurrentProjectIdChanged = projectId => {
  return {
    type: CURRENT_PROJECT_ID_CHANGED,
    payload: projectId
  }
};

export const getInviteDetails = (inviteId) => {
  return {
    type: GET_INVITE_DETAILS_REQUEST,
    payload: inviteId
  }
};

export const getInviteDetailsSuccess = inviteDetails => {
  return {
    type: GET_INVITE_DETAILS_SUCCESS,
    payload: inviteDetails
  }
};

export const acceptInvite = (inviteId, callback) => {
  return {
    type: ACCEPT_INVITE_REQUEST,
    payload: { inviteId, callback }
  }
};

export const acceptInviteSuccess = () => {
  return {
    type: ACCEPT_INVITE_SUCCESS,
  }
};

export const deleteUserFromProject = (projectId, userId, callback) => {
  return {
    type: DELETE_USER_FROM_PROJECT_REQUEST,
    payload: { projectId, userId, callback }
  }
};

export const deleteUserFromProjectSuccess = () => {
  return {
    type: DELETE_USER_FROM_PROJECT_SUCCESS,
  }
};

export const apiError = error => {
  return {
    type: AUTH_USER_API_ERROR,
    payload: error,
  }
}