import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logoutUser } from "../../store/actions";

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logoutUser(null));
  }, [dispatch]);

  return <></>;
}

export default withRouter(Logout);
