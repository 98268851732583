import { all, fork, call, put, takeLatest } from "redux-saga/effects";
import { CHANGE_PASSWORD_REQUEST } from "./actionTypes";
import { changePasswordSuccess, apiError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
const fireBaseBackend = getFirebaseBackend();


function* changePassword({ payload: {email, currentPassword, newPassword, callback} }) {
  try {
    const response = yield call(
      fireBaseBackend.changePassword,
      email,
      currentPassword,
      newPassword
    )
    yield put(changePasswordSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* watchChangePasswordRequests() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword)
}

function* authChangePasswordSaga() {
  yield all([
    fork(watchChangePasswordRequests),
  ]);
}

export default authChangePasswordSaga;
