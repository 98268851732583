import PropTypes from 'prop-types';
import React from "react";

import { connect, useSelector } from "react-redux";

import { Link } from "react-router-dom";


// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
// temporary disable notification dropdown:
//import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";

import mainLogoSm from "../../assets/images/logo-sm.svg";
import mainLogo from "../../assets/images/logo.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  // changeSidebarType,
} from "../../store/actions";

const Header = props => {

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  function formatBalanceAmount(amount) {
    let resultAmount = '';
    if (amount) {
      resultAmount = amount.substring(0, amount.length - 3); // removing extra "000" at the end to make it like "$123.45000" -> "$123.45"
    };

    return resultAmount;
  }

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  // creates a string like "$123.45" or "" if balance amount is not set
  let balanceAmount = props?.projectDetails?.project?.balanceAmountFormatted ? "$" + formatBalanceAmount(props?.projectDetails?.project?.balanceAmountFormatted) : "";

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={mainLogoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={mainLogo} alt="" height="20" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={mainLogoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={mainLogo} alt="" height="20" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

          </div>

          <div className="d-flex">
            <div className="d-flex align-items-center">
              <Link to={`/projects/${currentProjectId}/billing/balance`} title={props.t("Go To Project Balance")}>
                <span>{balanceAmount}</span>
              </Link>
            </div>

            {/* temporary disable notification dropdown
            <NotificationDropdown />
            */}

            <ProfileMenu />

          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  // changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  toggleLeftmenu: PropTypes.func,
  projectDetails: PropTypes.any,
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    projectDetails: state.Project.projectDetails
  };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  // changeSidebarType,
})(withTranslation()(Header));
