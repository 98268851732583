import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Table, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getPaymentsHistoryForProject,
} from "../../store/actions";

import {constructPaymentMethodBrandLogo} from "./common";

import '../datatables.scss';
const PaymentsHistory = () => {

  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const paymentsHistoryList = useSelector((state) => state.Billing.paymentsHistoryList);
  const paymentsHistoryLimit = useSelector((state) => state.Billing.paymentsHistoryLimit);
  const paymentsHistoryOffset = useSelector((state) => state.Billing.paymentsHistoryOffset);
  const paymentsHistoryTotalCount = useSelector((state) => state.Billing.paymentsHistoryTotalCount);
  const paymentsHistoryLoading = useSelector((state) => state.Billing.loading);

  const pageSize = paymentsHistoryLimit || 20;

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getPaymentsHistoryForProject(currentProjectId, pageSize, 0));
    }
  }, [dispatch, currentProjectId, pageSize]);

  const onNextPageClick = () => {
    if (paymentsHistoryOffset + pageSize < paymentsHistoryTotalCount) {
      dispatch(getPaymentsHistoryForProject(currentProjectId, pageSize, paymentsHistoryOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (paymentsHistoryOffset !== 0) {
      dispatch(getPaymentsHistoryForProject(currentProjectId, pageSize, paymentsHistoryOffset - pageSize));
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Billing" breadcrumbItem="Payments History" />
          <Card>
            <CardBody>
              <Row>
                <Col className="col-12">
                  {paymentsHistoryLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!paymentsHistoryLoading &&
                    <Table className="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th>Amount</th>
                          <th>Payment Method</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(!paymentsHistoryList || !Array.isArray(paymentsHistoryList) || paymentsHistoryList.length === 0) && <tr><td colSpan={3} className="text-center">Empty payments history</td></tr>}
                        {
                          paymentsHistoryList && Array.isArray(paymentsHistoryList) && paymentsHistoryList.length > 0 && paymentsHistoryList.map((h, i) => <tr key={i} className="align-middle">
                            <th scope="row">${h.paymentAmountFormatted}</th>
                            <td>
                              {constructPaymentMethodBrandLogo(h?.brand)}
                              &nbsp; **** **** **** {h.last4}
                            </td>
                            <td>{!h.paymentTimestampMs ? '' : new Date(h.paymentTimestampMs).toLocaleString()}</td>
                          </tr>)
                        }
                      </tbody>
                    </Table>
                  }
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  {!paymentsHistoryLoading &&
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            paymentsHistoryOffset === 0 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={onPreviousPageClick}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            paymentsHistoryOffset + pageSize >= paymentsHistoryTotalCount ? "next disabled" : "next"
                          }
                        >
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={onNextPageClick}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

PaymentsHistory.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  billing: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    billing: state.Billing,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(PaymentsHistory)));