/* TOKEN */
export const GET_TOKENS_FOR_PROJECT_REQUEST = "GET_TOKENS_FOR_PROJECT_REQUEST";
export const GET_TOKENS_FOR_PROJECT_SUCCESS = "GET_TOKENS_FOR_PROJECT_SUCCESS";

export const GET_TOKEN_DETAILS_REQUEST = "GET_TOKEN_DETAILS_REQUEST";
export const GET_TOKEN_DETAILS_SUCCESS = "GET_TOKEN_DETAILS_SUCCESS";

export const UPDATE_TOKEN_DETAILS_REQUEST = "UPDATE_TOKEN_DETAILS_REQUEST";
export const UPDATE_TOKEN_DETAILS_SUCCESS = "UPDATE_TOKEN_DETAILS_SUCCESS";

export const DELETE_TOKEN_REQUEST = "DELETE_TOKEN_REQUEST";
export const DELETE_TOKEN_SUCCESS = "DELETE_TOKEN_SUCCESS";

export const TOKEN_API_ERROR = "TOKEN_API_ERROR";
