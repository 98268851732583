import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, Link, useHistory, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table, Button, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getNumbersForProject,
  deleteNumber
} from "../../store/actions";

import {formatTNLocation, formatTNType} from './utils';

import './numbers.scss';

const NumbersList = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const telephoneNumbersList = useSelector((state) => state.Numbers.telephoneNumbersList);
  const telephoneNumbersLimit = useSelector((state) => state.Numbers.telephoneNumbersLimit);
  const telephoneNumbersOffset = useSelector((state) => state.Numbers.telephoneNumbersOffset);
  const telephoneNumbersTotalCount = useSelector((state) => state.Numbers.telephoneNumbersTotalCount);
  const telephoneNumbersLoading = useSelector((state) => state.Numbers.loading);

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [redirectToDetailsTelephoneNumberId, setRedirectToDetailsTelephoneNumberId] = useState(''); // a flag to redirect to MessageDetails form (with particular messageId)

  const pageSize = telephoneNumbersLimit || 20;

  const redirectStatus = new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const redirectTelephoneNumber = new URLSearchParams(window?.location?.search).get(
    'tn'
  );

  useEffect(() => {

    if (redirectStatus === 'delete-tn-succeeded') {
      setSuccessMessage(`Telephone number ${redirectTelephoneNumber} was successfully released`);
    }
    
    if (redirectStatus === 'purchase-tn-succeeded') {
      setSuccessMessage(`Telephone number ${redirectTelephoneNumber} was successfully purchased`);
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }
  }, [redirectStatus, redirectTelephoneNumber]);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getNumbersForProject(currentProjectId, pageSize, 0));
    }
  }, [dispatch, currentProjectId, pageSize]);

  const onNextPageClick = () => {
    if (telephoneNumbersOffset + pageSize < telephoneNumbersTotalCount) {
      dispatch(getNumbersForProject(currentProjectId, pageSize, telephoneNumbersOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (telephoneNumbersOffset !== 0) {
      dispatch(getNumbersForProject(currentProjectId, pageSize, telephoneNumbersOffset - pageSize));
    }
  }

  const onGetNumberDetailsClick = (telephoneNumberId) => {
    setRedirectToDetailsTelephoneNumberId(telephoneNumberId); // show the details telephone number form for a specific telephoneNumberId
  }

  const onDeleteNumberClick = (telephoneNumberId, telephoneNumber) => {
    if (window.confirm(`Are you sure you want to release ${telephoneNumber}?`) === true) {
      dispatch(deleteNumber(currentProjectId, telephoneNumberId, () => {
        dispatch(getNumbersForProject(currentProjectId, pageSize, 0));
        history.push(`/projects/${currentProjectId}/numbers?redirect_status=delete-tn-succeeded&tn=${encodeURIComponent(telephoneNumber)}`);
      }))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content telephone-numbers-list-table">
        <Container fluid>
          <Breadcrumbs title="Phone Numbers" breadcrumbItem="Purchased Phone Numbers" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                    {redirectToDetailsTelephoneNumberId && <Redirect push to={`/projects/${currentProjectId}/numbers/${redirectToDetailsTelephoneNumberId}`} />}
                    {!telephoneNumbersLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
                    {!telephoneNumbersLoading &&
                        <div className="actions clearfix">
                          <ul>
                            <li
                            >
                              <Link
                                to={`/projects/${currentProjectId}/numbers/search`}
                                className="btn btn-primary"
                              >
                                + Purchase Number
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {telephoneNumbersLoading && <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      }
                      {!telephoneNumbersLoading &&
                        <Table className="table table-striped table-responsive">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Number</th>
                              <th>Type</th>
                              <th>Capabilities</th>
                              <th>Price</th>
                              <th>Purchase Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(!telephoneNumbersList || !Array.isArray(telephoneNumbersList) || telephoneNumbersList.length === 0) && <tr><td colSpan={6} className="text-center">No phone numbers yet</td></tr>}
                            {
                              telephoneNumbersList && Array.isArray(telephoneNumbersList) && telephoneNumbersList.length > 0 && telephoneNumbersList.map((tn, i) => <tr key={i} className="align-middle">
                                <td style={{ width: "10%" }}>
                                  <i className="uil-trash-alt" title="Release this phone number" role="button" onClick={() => onDeleteNumberClick(tn.telephoneNumberId, tn.telephoneNumber)}></i>
                                </td>
                                <td>
                                  <Button style={{ padding: "0" }} color="link" className="waves-effect" onClick={() => onGetNumberDetailsClick(tn.telephoneNumberId)} title="Go to phone number details">{tn.telephoneNumber}</Button>
                                  <br />
                                  <div><strong>{formatTNLocation(tn)}</strong></div>
                                </td>
                                <td>
                                  {formatTNType(tn)}
                                </td>
                                <td>
                                  {tn.capabilities['voice'] &&
                                    <i className="uil-phone-alt" title="Voice"></i>
                                  }
                                  &nbsp;
                                  {tn.capabilities['sms'] &&
                                    <i className="uil-comment-alt-message" title="SMS"></i>
                                  }
                                  &nbsp;
                                  {tn.capabilities['mms'] &&
                                    <i className="uil-comment-alt-image" title="MMS"></i>
                                  }
                                </td>
                                <td>${tn.costFormatted}/month</td>
                                <td>{!tn.purchasedTimestampMs ? '' : new Date(tn.purchasedTimestampMs).toLocaleString()}</td>
                              </tr>)
                            }
                          </tbody>
                        </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {!telephoneNumbersLoading &&
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                telephoneNumbersOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={
                                telephoneNumbersOffset + pageSize >= telephoneNumbersTotalCount ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

NumbersList.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(NumbersList)));