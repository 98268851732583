import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {
  getTokensForProject,
  deleteToken
} from "../../store/actions";

import '../datatables.scss';


const Tokens = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const tokensList = useSelector((state) => state.Token.tokensList);
  const tokensLoading = useSelector((state) => state.Token.loading);
  const tokensAPIError = useSelector((state) => state.Token.error);
  const [redirectToAddToken, setRedirectToAddToken] = useState(false); // a flag to redirect to AddToken form
  const [redirectToDetailsTokenId, setRedirectToDetailsTokenId] = useState(''); // a flag to redirect to UpdateToken form (with particular tokenId)
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  const redirectStatus = new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getTokensForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId, redirectStatus]);

  useEffect(() => {

    if (redirectStatus === 'delete-token-succeeded') {
      setSuccessMessage("Token was successfully deleted");
    }

    if (redirectStatus === 'update-token-succeeded') {
      setSuccessMessage("Token was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }
  }, [redirectStatus]);


  const onAddTokenClick = () => {
    setRedirectToAddToken(true); // show the add token form
  }

  const onDeleteTokenClick = (tokenId) => {
    if (window.confirm("Are you sure you want to delete this token?") === true) {
      dispatch(deleteToken(currentProjectId, tokenId, () => {
        dispatch(getTokensForProject(currentProjectId));
        history.push(`/projects/${currentProjectId}/api/tokens?redirect_status=delete-token-succeeded`);
      }))
    }
  }

  const onUpdateTokenClick = (tokenId) => {
    setRedirectToDetailsTokenId(tokenId); // show the details token form for a specific tokenId
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="API" breadcrumbItem="Tokens" />
          <Card>
            <CardBody>
              {redirectToAddToken && <Redirect push to={`/projects/${currentProjectId}/api/tokens/add`} />}
              {redirectToDetailsTokenId && <Redirect push to={`/projects/${currentProjectId}/api/tokens/${redirectToDetailsTokenId}`} />}
              {tokensLoading && <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              }
              {!tokensLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
              {!tokensLoading && tokensAPIError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
              {!tokensLoading &&
                <div className="actions clearfix">
                  <ul>
                    <li
                    >
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md"
                        onClick={onAddTokenClick}
                      >
                        Generate Token
                      </Button>
                    </li>
                  </ul>
                </div>
              }
              {!tokensLoading &&
                <>
                  <Row>
                    <Col className="col-12">
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Scopes</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(!tokensList || !Array.isArray(tokensList) || tokensList.length === 0) && <tr><td colSpan={3} className="text-center">No tokens yet</td></tr>}
                          {
                            tokensList && Array.isArray(tokensList) && tokensList.length > 0 && tokensList.map((t, i) => <tr key={i} className="align-middle">
                              <td style={{ width: "10%" }}>
                                <i className="uil-trash-alt" title="Remove this token" role="button" onClick={() => onDeleteTokenClick(t.tokenId)}></i>
                              </td>
                              <td style={{ width: "30%" }}><Button style={{ padding: "0" }} color="link" className="waves-effect" onClick={() => onUpdateTokenClick(t.tokenId)} title="Go to token details">{t.name}</Button></td>
                              <td style={{ width: "60%" }}>{Array.isArray(t.scopes) ? t.scopes.join(', ') : ''}</td>
                            </tr>)
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Tokens.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  tokens: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    token: state.Token,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Tokens)));