/* AUTH_USER */
export const GET_AUTH_USER_PROJECTS_REQUEST = "GET_AUTH_USER_PROJECTS_REQUEST";
export const GET_AUTH_USER_PROJECTS_SUCCESS = "GET_AUTH_USER_PROJECTS_SUCCESS";

export const AUTH_USER_CHANGED = "AUTH_USER_CHANGED";
export const CURRENT_PROJECT_ID_CHANGED = "CURRENT_PROJECT_ID_CHANGED";

export const GET_AUTH_USERS_FOR_PROJECT_REQUEST = "GET_AUTH_USERS_FOR_PROJECT_REQUEST";
export const GET_AUTH_USERS_FOR_PROJECT_SUCCESS = "GET_AUTH_USERS_FOR_PROJECT_SUCCESS";

export const GET_INVITE_DETAILS_REQUEST = "GET_INVITE_DETAILS_REQUEST";
export const GET_INVITE_DETAILS_SUCCESS = "GET_INVITE_DETAILS_SUCCESS";

export const ACCEPT_INVITE_REQUEST = "ACCEPT_INVITE_REQUEST";
export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS";

export const DELETE_USER_FROM_PROJECT_REQUEST = "DELETE_USER_FROM_PROJECT_REQUEST";
export const DELETE_USER_FROM_PROJECT_SUCCESS = "DELETE_USER_FROM_PROJECT_SUCCESS";

export const AUTH_USER_API_ERROR = "AUTH_USER_API_ERROR";
