import {
  GET_TOKENS_FOR_PROJECT_REQUEST,
  GET_TOKENS_FOR_PROJECT_SUCCESS,
  GET_TOKEN_DETAILS_REQUEST,
  GET_TOKEN_DETAILS_SUCCESS,
  UPDATE_TOKEN_DETAILS_REQUEST,
  UPDATE_TOKEN_DETAILS_SUCCESS,
  DELETE_TOKEN_REQUEST,
  DELETE_TOKEN_SUCCESS,
  TOKEN_API_ERROR
} from "./actionTypes";

export const getTokensForProject = projectId => {
  return {
    type: GET_TOKENS_FOR_PROJECT_REQUEST,
    payload: projectId
  }
};

export const getTokensForProjectSuccess = tokens => {
  return {
    type: GET_TOKENS_FOR_PROJECT_SUCCESS,
    payload: tokens
  }
};

export const getTokenDetails = (projectId, tokenId) => {
  return {
    type: GET_TOKEN_DETAILS_REQUEST,
    payload: { projectId, tokenId }
  }
};

export const getTokenDetailsSuccess = tokenDetails => {
  return {
    type: GET_TOKEN_DETAILS_SUCCESS,
    payload: tokenDetails
  }
};

export const updateToken = (projectId, tokenId, tokenName, tokenScopes, callback) => {
  return {
    type: UPDATE_TOKEN_DETAILS_REQUEST,
    payload: { projectId, tokenId, tokenName, tokenScopes, callback }
  }
};

export const updateTokenSuccess = () => {
  return {
    type: UPDATE_TOKEN_DETAILS_SUCCESS,
  }
};

export const deleteToken = (projectId, tokenId, callback) => {
  return {
    type: DELETE_TOKEN_REQUEST,
    payload: { projectId, tokenId, callback }
  }
};

export const deleteTokenSuccess = () => {
  return {
    type: DELETE_TOKEN_SUCCESS,
  }
};

export const apiError = error => {
  return {
    type: TOKEN_API_ERROR,
    payload: error,
  }
}