import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Modal, Form, Label, Input, Button, FormFeedback, InputGroup, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { authUserService } from "../../services";

const InviteUserDialog = (props) => {
  var dialogIsOpen = props.isOpen ?? false;
  const closeDialog = () =>{
    setIsLoading(false);
    setSuccessMessage('');
    setErrorMessage('');
    setInitialUserEmail('');
    return props.closeDialog();
  }

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show an error message
  const [initialUserEmail, setInitialUserEmail] = useState('');

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userEmail: initialUserEmail,
    },
    validationSchema: Yup.object({
      userEmail: Yup.string().required("Please enter user email").email("Incorrect user email"),
    }),
    onSubmit: async (values) => {
      if (!isLoading && successMessage) { // we already successfully created an invite and waiting for redirect
        return;
      }

      try {
        setIsLoading(true);
        let response = await authUserService.inviteUser(currentProjectId, values.userEmail);
        let createdInviteId = response?.createdInvite?.inviteId;
        if (!createdInviteId) {
          setErrorMessage("Something went wrong while inviting a user");
        } else {
          setIsLoading(true);
          setErrorMessage("");
          setSuccessMessage(`Successfully invited ${values.userEmail}. Closing this dialog..`);
          setTimeout(() => {
            closeDialog();
            //window.location = `/projects/${createdProjectId}/dashboard`; // this guarantees full page reload, including setting currentProjectId = createdProjectId
          }, 1500);
        }
      } catch (err) {
        console.log(err);
        setErrorMessage("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={dialogIsOpen}
        centered={true}
      >
        <Form
          className="form-group"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Invite User to Project
            </h5>
            <button
              type="button"
              onClick={() => {
                closeDialog();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              {isLoading && <div><div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div></div>
              }
              {!isLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
              {!isLoading && errorMessage ? <Alert color="danger" role="alert">{errorMessage}</Alert> : null}
              {!isLoading &&
                <>
                  <Label className="form-label">User Email</Label>
                  <InputGroup>
                    <Input
                      name="userEmail"
                      placeholder="Enter User Email"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.userEmail || ""}
                      invalid={
                        validation.errors.userEmail ? true : false
                      }
                    />
                    {validation.errors.userEmail ? (
                      <FormFeedback type="invalid">{validation.errors.userEmail}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </>
              }
            </div>
          </div>
          <div className="modal-footer">
            {!isLoading &&
              <>
                <Button
                  type="submit"
                  color="primary"
                  className="w-md"
                >
                  Invite
                </Button>
                <Button
                  type="reset"
                  color="danger"
                  outline
                  className="w-md"
                  onClick={() => {
                    if (!isLoading && !successMessage) {
                      closeDialog();
                    }
                  }}
                >
                  Cancel
                </Button>
              </>
            }
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

InviteUserDialog.propTypes = {
  t: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(InviteUserDialog)));