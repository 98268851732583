import { get, post, del } from "../helpers/api_helper";
const _ = require('lodash');

class TelephoneNumberService {

  fetchTelephoneNumbers(projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/numbers?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  searchInventory(projectId, telephoneNumberType, maxResults, countryCode, lata, regionName, locationName, contains) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/numbers/search`,
        {
          type: telephoneNumberType,
          maxResults,
          countryCode,
          lata,
          regionName,
          locationName,
          contains
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  releaseTelephoneNumber(projectId, telephoneNumberId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/numbers/${telephoneNumberId}`)
        .then( () => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  purchaseTelephoneNumber(projectId, telephoneNumber) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/numbers`,
        {
          telephoneNumber
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchTelephoneNumberDetails(projectId, telephoneNumberId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/numbers/${telephoneNumberId}`)
        .then(response => {
          const message = _.get(response, 'telephoneNumber', {});
          resolve(message);
        }).catch(error => {
          reject(error);
        })
    });

  }

}

export const telephoneNumberService = new TelephoneNumberService();