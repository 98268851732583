import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table, Button, Form, Input, Label, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Formik Validation
//import * as Yup from "yup";
import { useFormik } from "formik";

import {
  searchInventoryForProject
} from "../../store/actions";

import { usStates } from "../../helpers/utils";
import { telephoneNumberService } from "../../services";

import './numbers.scss';

const SearchInventoryFilter = (props) => {

  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const inventoryTelephoneNumbersList = useSelector((state) => state.Numbers.inventoryTelephoneNumbersList);
  const inventoryTelephoneNumberType = useSelector((state) => state.Numbers.inventoryTelephoneNumberType);
  const inventoryTelephoneNumbersRegionName = useSelector((state) => state.Numbers.inventoryTelephoneNumbersRegionName);
  const inventoryTelephoneNumbersLocationName = useSelector((state) => state.Numbers.inventoryTelephoneNumbersLocationName);
  const inventoryTelephoneNumbersContains = useSelector((state) => state.Numbers.inventoryTelephoneNumbersContains);
  const inventoryTelephoneNumbersLata = useSelector((state) => state.Numbers.inventoryTelephoneNumbersLata);
  const inventoryTelephoneNumbersMaxResults = useSelector((state) => state.Numbers.inventoryTelephoneNumbersMaxResults);
  const inventoryTelephoneNumbersCountryCode = useSelector((state) => state.Numbers.inventoryTelephoneNumbersCountryCode);

  // this will load phone numbers list for the first page opening
  useEffect(() => {
    if (currentProjectId && !inventoryTelephoneNumbersList) { // this will be executed only once because inventoryTelephoneNumbersList is undefined for a first load only
      dispatch(searchInventoryForProject(currentProjectId, inventoryTelephoneNumberType, inventoryTelephoneNumbersMaxResults, inventoryTelephoneNumbersCountryCode, inventoryTelephoneNumbersLata, inventoryTelephoneNumbersRegionName, inventoryTelephoneNumbersLocationName, inventoryTelephoneNumbersContains));
    }
  }, [dispatch, currentProjectId, inventoryTelephoneNumbersList, inventoryTelephoneNumberType, inventoryTelephoneNumbersMaxResults, inventoryTelephoneNumbersCountryCode, inventoryTelephoneNumbersLata, inventoryTelephoneNumbersRegionName, inventoryTelephoneNumbersLocationName, inventoryTelephoneNumbersContains]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      telephoneNumberType: inventoryTelephoneNumberType,
      containsFilter: inventoryTelephoneNumbersContains,
      state: inventoryTelephoneNumbersRegionName,
      city: inventoryTelephoneNumbersLocationName,
      countryCode: inventoryTelephoneNumbersCountryCode,
      lata: inventoryTelephoneNumbersLata,
      maxResults: inventoryTelephoneNumbersMaxResults,
    },
    onSubmit: (values) => {
      let cityFilter = values.state ? values.city : '' /* cannot set city if state is empty */
      dispatch(searchInventoryForProject(currentProjectId, values.telephoneNumberType, values.maxResults, values.countryCode, values.lata, values.state, cityFilter, values.containsFilter));
    }
  });

  const isTollFree = (val) => {
    return validation.values.telephoneNumberType === "toll-free"
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col className="col-12">
              <Form
                className="form-group search-inventory"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col md={6}>
                    <div className="hstack gap-4">
                      <div className="form-check">
                        <label>
                          <Input type="radio"
                            className="form-check-input"
                            name="telephoneNumberType"
                            value="local"
                            id="telephoneNumberTypeLocal"
                            onChange={validation.handleChange}
                            defaultChecked={!isTollFree()}
                          />
                          Local
                        </label>
                      </div>
                      <div className="form-check">
                        <label>
                          <Input type="radio"
                            className="form-check-input"
                            name="telephoneNumberType"
                            value="toll-free"
                            id="telephoneNumberTypeTollFree"
                            onChange={validation.handleChange}
                            defaultChecked={isTollFree()}
                          />
                          Toll-Free
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={isTollFree() ? 6 : 4}>
                    <div className="mb-3">
                      <Label className="form-label">Contains digits</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="containsFilter"
                        defaultValue={validation.values.containsFilter}
                        minLength={3}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                  {!isTollFree() &&
                    <>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">State</Label>
                          <select
                            className="form-control"
                            name="state"
                            onChange={validation.handleChange}
                            defaultValue={validation.values.state}
                            disabled={isTollFree()}
                          >
                            <option key="any" value="">Any</option>
                            {[...usStates.entries()].map(([stateCode, stateLabel]) =>
                              <option key={stateCode} value={stateCode}>{stateLabel}</option>
                            )}
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">City</Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="city"
                            defaultValue={validation.values.city}
                            onChange={validation.handleChange}
                            disabled={isTollFree() || !validation.values.state}
                          />
                        </div>
                      </Col>
                    </>
                  }
                </Row>
                <div>
                  <button type="submit" className="btn btn-primary w-md">
                    Apply Filter
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

const SearchInventory = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const inventoryTelephoneNumbersList = useSelector((state) => state.Numbers.inventoryTelephoneNumbersList);
  const telephoneNumbersLoading = useSelector((state) => state.Numbers.loading);

  const [redirectToTelephonesList, setRedirectToTelephonesList] = useState(false); // a flag to redirect back
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message


  const onPurchasePhoneNumberClick = async (telephoneNumber) => {
    if (window.confirm(`Are you sure you want to purchase ${telephoneNumber}?`) === true) {
      try {
        setIsPurchasing(true);
        await telephoneNumberService.purchaseTelephoneNumber(currentProjectId, telephoneNumber);
        history.push(`/projects/${currentProjectId}/numbers?redirect_status=purchase-tn-succeeded&tn=${encodeURIComponent(telephoneNumber)}`)
      } catch (err) {
        console.log(err);
        setErrorMessage("Something went wrong");
      } finally {
        setIsPurchasing(false);
      }
    }
  }

  const onCancelSearchInventoryClick = () => {
    setRedirectToTelephonesList(true);
  }

  const formatTNLocation = (tn) => {
    let locArr = [tn?.locationName, tn?.regionName, tn?.countryCode];
    return locArr.filter(l => l /* filtering non-empty values only */).join(', ');
  }

  const formatTNType = (tn) => {
    if (tn.type === 'local') {
      return 'local';
    }

    if (tn.type === 'toll-free') {
      return 'toll-free';
    }

    if (tn.type === 'short') {
      return 'short code';
    }

    return '';
  }

  return (
    <React.Fragment>
      {redirectToTelephonesList &&
        <Redirect push to={`/projects/${currentProjectId}/numbers`} />
      }
      <div className="page-content telephone-numbers-list-table">
        <Container fluid>
          <Breadcrumbs title="Phone Numbers" breadcrumbItem="Purchase Number" />
          <Row>
            <Col className="col-12">
              <SearchInventoryFilter />
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      {(telephoneNumbersLoading || isPurchasing) && <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      }
                      {!(telephoneNumbersLoading || isPurchasing) && errorMessage ? <Alert color="danger" role="alert">{errorMessage}</Alert> : null}
                      {!telephoneNumbersLoading &&

                        <>
                          <Table className="table table-striped table-responsive">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Number</th>
                                <th>Type</th>
                                <th>Capabilities</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(!inventoryTelephoneNumbersList || !Array.isArray(inventoryTelephoneNumbersList) || inventoryTelephoneNumbersList.length === 0) && <tr><td colSpan={5} className="text-center">No phone numbers for your search criteria</td></tr>}
                              {
                                inventoryTelephoneNumbersList && Array.isArray(inventoryTelephoneNumbersList) && inventoryTelephoneNumbersList.length > 0 && inventoryTelephoneNumbersList.map((tn, i) => <tr key={i} className="align-middle">
                                  <td style={{ width: "10%" }}>
                                    <Button
                                      color="primary"
                                      outline
                                      className="waves-effect waves-light btn-sm"
                                      title={`Purchase phone number ${tn.telephoneNumber}`}
                                      onClick={() => onPurchasePhoneNumberClick(tn.telephoneNumber)}
                                    >
                                      Purchase
                                    </Button>
                                  </td>
                                  <td>
                                    {tn.telephoneNumber}
                                    <br />
                                    <div><strong>{formatTNLocation(tn)}</strong></div>
                                  </td>
                                  <td>
                                    {formatTNType(tn)}
                                  </td>
                                  <td>
                                    {tn.capabilities['voice'] &&
                                      <i className="uil-phone-alt" title="Voice"></i>
                                    }
                                    &nbsp;
                                    {tn.capabilities['sms'] &&
                                      <i className="uil-comment-alt-message" title="SMS"></i>
                                    }
                                    &nbsp;
                                    {tn.capabilities['mms'] &&
                                      <i className="uil-comment-alt-image" title="MMS"></i>
                                    }
                                    &nbsp;
                                    {tn.capabilities['e911'] &&
                                      <i className="uil-exclamation-triangle" title="E911"></i>
                                    }
                                    &nbsp;
                                    {tn.capabilities['cnam'] &&
                                      <i className="uil-search" title="CNAM Lookup"></i>
                                    }
                                  </td>
                                  <td>${tn.costFormatted}/month</td>
                                  <td>{!tn.purchasedTimestampMs ? '' : new Date(tn.purchasedTimestampMs).toLocaleString()}</td>
                                </tr>)
                              }
                            </tbody>
                          </Table>
                        </>
                      }
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <div className="d-flex flex-wrap gap-3 mt-3">
                      <Button
                        type="reset"
                        color="danger"
                        outline
                        className="w-md"
                        onClick={onCancelSearchInventoryClick}
                      >
                        Back
                      </Button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SearchInventory.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(SearchInventory)));