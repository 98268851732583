import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import ProjectBalanceCard from './ProjectBalanceCard';
import AutoReloadBalanceCard from './AutoReloadBalanceCard';

const ProjectBalance = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Billing" breadcrumbItem="Project Balance" />
          <Row>
            <Col className="col-12">
              <ProjectBalanceCard />

              <AutoReloadBalanceCard />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProjectBalance.propTypes = {
  t: PropTypes.any,
}

export default (withRouter(withTranslation()(ProjectBalance)));