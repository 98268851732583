import { all, fork, call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  GET_EVENTS_FOR_PROJECT_REQUEST,
} from "./actionTypes";
import {
  getEventsForProjectSuccess,
  apiError
} from "./actions";

//Include Both Helper File with needed methods
import { eventService } from "../../services";

function* getEvents({ payload: {projectId, limit, offset} }) {
  try {
    const response = yield call(
      eventService.fetchEvents,
      projectId,
      limit,
      offset
    )
    yield put(getEventsForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* watchEventsRequests() {
  yield takeEvery(GET_EVENTS_FOR_PROJECT_REQUEST, getEvents)
}

function* eventSaga() {
  yield all([
    fork(watchEventsRequests),
  ]);
}

export default eventSaga;
