// @flow
import {
  GET_BRANDS_FOR_PROJECT_REQUEST,
  GET_BRANDS_FOR_PROJECT_SUCCESS,
  GET_BRAND_DETAILS_REQUEST,
  GET_BRAND_DETAILS_SUCCESS,
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_SUCCESS,
  CSP_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,

  brandDetails: undefined,

  brandsList: [],
  brandsLimit: 10,
  brandsOffset: 0,
  brandsTotalCount: 0,

  campaignsList: [],
  campaignsLimit: 10,
  campaignsOffset: 0,
  campaignsTotalCount: 0,
};

const Csp = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANDS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_BRANDS_FOR_PROJECT_SUCCESS:
      let bnds = [];
      if (Array.isArray(action.payload.brandsList) && action.payload.brandsList.length > 0) {
        bnds = action.payload.brandsList;
      }

      return {
        ...state,
        loading: false,
        brandsList: bnds,
        brandsLimit: action.payload.brandsLimit,
        brandsOffset: action.payload.brandsOffset,
        brandsTotalCount: action.payload.brandsTotalCount,
      }

    case GET_BRAND_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        brandDetails: undefined, // following the pattern similar to tokens
        error: ''
      };

    case GET_BRAND_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        brandDetails: action.payload,
      }

    case GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_CAMPAIGNS_SUCCESS:
      let campaigns = [];
      if (Array.isArray(action.payload.campaignsList) && action.payload.campaignsList.length > 0) {
        campaigns = action.payload.campaignsList;
      }

      return {
        ...state,
        loading: false,
        campaignsList: campaigns,
        campaignsLimit: action.payload.campaignsLimit,
        campaignsOffset: action.payload.campaignsOffset,
        campaignsTotalCount: action.payload.campaignsTotalCount,
      }

    case CSP_API_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default Csp;
