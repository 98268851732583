import { get } from "../helpers/api_helper";
const _ = require('lodash');

class CspService {

  fetchBrands(projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/brands?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchBrandDetails(projectId, brandId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/brands/${brandId}`)
        .then(response => {
          const brand = _.get(response, 'brand', {});
          resolve(brand);
        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchCampaigns(projectId, brandId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/campaigns?offset=${offsetParam}`;

    if (brandId) { // using different URL in case we need to get campaigns only for a particular brand
      url = `/projects/${projectId}/brands/${brandId}/campaigns?offset=${offsetParam}`;
    }

    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

}

export const cspService = new CspService();