import { call, put, takeLatest, all, fork } from "redux-saga/effects";

// Login Redux States
import { GET_PROJECT_DETAILS_REQUEST, UPDATE_PROJECT_REQUEST, UPDATE_INBOUND_MESSAGE_WEBHOOK_REQUEST, UPDATE_AUTO_CHARGE_REQUEST } from "./actionTypes";
import { getProjectDetailsSuccess, updateProjectSuccess, updateInboundMessageWebhookSuccess, updateAutoChargeSuccess, apiError } from "./actions";

//Include Both Helper File with needed methods
import { projectService} from "../../services";

function* getProjectDetails({ payload: projectId }) {
  try {
    const response = yield call(
      projectService.fetchProjectDetails,
      projectId
    )
    yield put(getProjectDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* updateProject({ payload: {projectId, name, callback} }) {
  try {
    const response = yield call(
      projectService.updateProject,
      projectId,
      name
    )
    yield put(updateProjectSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* updateInboundMessageWebhook({ payload: {projectId, url, headers, callback} }) {
  try {
    const response = yield call(
      projectService.updateInboundMessageWebhook,
      projectId,
      url,
      headers
    )
    yield put(updateInboundMessageWebhookSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* updateAutoCharge({ payload: {projectId, thresholdAmount, topUpAmount, paymentMethodId, callback} }) {
  try {
    const response = yield call(
      projectService.updateAutoCharge,
      projectId,
      thresholdAmount,
      topUpAmount,
      paymentMethodId
    )
    yield put(updateAutoChargeSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* watchGetProjectDetailsRequests() {
  yield takeLatest(GET_PROJECT_DETAILS_REQUEST, getProjectDetails)
}

function* watchUpdateProjectRequests() {
  yield takeLatest(UPDATE_PROJECT_REQUEST, updateProject)
}

function* watchUpdateInboundMessageWebhookRequests() {
  yield takeLatest(UPDATE_INBOUND_MESSAGE_WEBHOOK_REQUEST, updateInboundMessageWebhook)
}

function* watchUpdateAutoChargeRequests() {
  yield takeLatest(UPDATE_AUTO_CHARGE_REQUEST, updateAutoCharge)
}

function* projectSaga() {
  yield all([
    fork(watchGetProjectDetailsRequests),
    fork(watchUpdateProjectRequests),
    fork(watchUpdateInboundMessageWebhookRequests),
    fork(watchUpdateAutoChargeRequests),
  ]);
}

export default projectSaga;
