import { get, post, put, del } from "../helpers/api_helper";
const _ = require('lodash');

class TokenService {

  fetchTokens(projectId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/tokens`)
        .then(response => {
          const tokens = _.get(response, 'tokens', []);
          resolve(tokens);
        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchTokenDetails(projectId, tokenId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/tokens/${tokenId}`)
        .then(response => {
          const token = _.get(response, 'token', {});
          resolve(token);
        }).catch(error => {
          reject(error);
        })
    });

  }

  deleteToken(projectId, tokenId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/tokens/${tokenId}`)
        .then( () => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  generateToken(projectId, tokenName, tokenScopes) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/tokens`,
        {
          tokenName,
          tokenScopes
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  updateToken(tokenId, projectId, tokenName, tokenScopes) {
    return new Promise((resolve, reject) => {
      put(
        `/projects/${projectId}/tokens/${tokenId}`,
        {
          tokenName,
          tokenScopes
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

}

export const tokenService = new TokenService();