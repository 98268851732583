import { all, fork, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_PROFILE_DETAILS_REQUEST, UPDATE_PROFILE_DETAILS_REQUEST } from "./actionTypes";
import { getProfileDetailsSuccess, updateProfileSuccess, apiError } from "./actions";

import { profileService} from "../../services";


function* getProfileDetails() {
  try {
    const response = yield call(
      profileService.fetchProfileDetails
    )
    yield put(getProfileDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* updateProfile({ payload: {fullName, phone, companyName, callback} }) {
  try {
    const response = yield call(
      profileService.updateProfile,
      fullName,
      phone,
      companyName
    )
    yield put(updateProfileSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* watchGetProfileDetailsRequests() {
  yield takeEvery(GET_PROFILE_DETAILS_REQUEST, getProfileDetails)
}

function* watchUpdateProfileRequests() {
  yield takeLatest(UPDATE_PROFILE_DETAILS_REQUEST, updateProfile)
}

function* profileSaga() {
  yield all([
    fork(watchGetProfileDetailsRequests),
    fork(watchUpdateProfileRequests),
  ]);
}

export default profileSaga;
