import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Table, Alert } from "reactstrap";

import { EventDescription } from "../Events/EventDescription";

import { isNullOrUndefined } from "../../helpers/utils";

import {
  eventService
} from "../../services/event-service";

import "./dashboard.scss";

const LatestEventsCard = () => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [eventsList, setEventsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const apiError = useSelector((state) => state.Event.error);

  const pageSize = 5 ;

  // WE CANNOT USE EVENTS REDUX STORE NOT TO INTERFERE WITH "EVENTS" PAGE
  useEffect(() => {
    if (currentProjectId) {
      async function fetchData() {
        setIsLoading(true);
        let l = await eventService.fetchEvents(currentProjectId, pageSize, 0);
        setEventsList(l?.events);
        setIsLoading(false);
      }
      fetchData();
    }
  }, [currentProjectId, pageSize]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Latest Project Events</CardTitle>
          {isLoading && <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          }
          {!isLoading && apiError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
          {!isLoading &&
            <Table className="table table-striped table-responsive">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Balance Change</th>
                  <th>User</th>
                  <th>Event Date</th>
                </tr>
              </thead>
              <tbody>
                {(!eventsList || !Array.isArray(eventsList) || eventsList.length === 0) && <tr><td colSpan={4} className="text-center">No events yet</td></tr>}
                {
                  eventsList && Array.isArray(eventsList) && eventsList.length > 0 && eventsList.map((e, i) => <tr key={i} className="align-middle">
                    <td>
                      <EventDescription event={e} />
                    </td>
                    <td>
                      {!isNullOrUndefined(e.balanceChangeAmount) && e.balanceChangeAmount >= 0 &&
                        <span>${e.balanceChangeAmountFormatted}</span>
                      }
                      {!isNullOrUndefined(e.balanceChangeAmount) && e.balanceChangeAmount < 0 &&
                        <span>-${e.balanceChangeAmountFormatted.substring(1)}</span>
                      }
                      {isNullOrUndefined(e.balanceChangeAmount) &&
                        <span>N/A</span>
                      }
                    </td>
                    <td>{e.createdByUserEmail ?? 'system'}</td>
                    <td>{!e.createdTimestampMs ? '' : new Date(e.createdTimestampMs).toLocaleString()}</td>
                  </tr>)
                }
              </tbody>
            </Table>
          }
        </CardBody>
      </Card>

    </React.Fragment>
  );
};

export default LatestEventsCard;