import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Alert } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { projectService } from "../services";

const EmptyProjectsList = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show an error message

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      projectName: '',
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required("Please enter project name").max(18, "Name is too long").not(["+ Create a Project", "Create a Project"], "Incorrect project name"),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        let response = await projectService.createProject(values.projectName);
        let createdProjectId = response?.createdProject?.projectId;
        if (!createdProjectId) {
          setErrorMessage("Something went wrong while creating a new project");
        } else {
          setIsLoading(true);
          setErrorMessage("");
          setSuccessMessage("Successfully created a new project. Redirecting..");
          window.location = `/projects/${createdProjectId}/dashboard`; // this guarantees full page reload, including setting currentProjectId = createdProjectId
        }
      } catch (err) {
        console.log(err);
        setErrorMessage("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {isLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!isLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
                  {!isLoading && errorMessage ? <Alert color="danger" role="alert">{errorMessage}</Alert> : null}
                  {!isLoading && !successMessage &&
                    <>
                    <p className="card-title-desc">
                      It looks like you have not created a project yet. Projects behave like namespaces to logically separate your environments.
                      <br/>
                      Let's create a new project. You can change it's name later.
                    </p>
                    <Form
                      className="form-group top-up-balance"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row className="mb-3">
                        <Label className="form-label">Project Name</Label>
                        <InputGroup>
                          <Input
                            name="projectName"
                            placeholder="Enter Project Name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.projectName || ""}
                            invalid={
                              validation.touched.projectName && validation.errors.projectName ? true : false
                            }
                          />
                          {validation.touched.projectName && validation.errors.projectName ? (
                            <FormFeedback type="invalid">{validation.errors.projectName}</FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Row>
                      <Row className="mb-3">
                        <div className="d-flex flex-wrap gap-3 mt-3">
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Create New Project
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EmptyProjectsList.propTypes = {
  t: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(EmptyProjectsList)));