import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Button } from "reactstrap";

export const EventDescription = (props) => {
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [redirectToDetailsTelephoneNumberId, setRedirectToDetailsTelephoneNumberId] = useState(''); // a flag to redirect to MessageDetails form (with particular messageId)
  const [redirectToDetailsMessageId, setRedirectToDetailsMessageId] = useState(''); // a flag to redirect to MessageDetails form (with particular messageId)
  const [redirectToDetailsTokenId, setRedirectToDetailsTokenId] = useState(''); // a flag to redirect to MessageDetails form (with particular messageId)

  const onGetPhoneNumberDetailsClick = (telephoneNumberId) => {
    setRedirectToDetailsTelephoneNumberId(telephoneNumberId); // show the details message form for a specific messageId
  }

  const onGetMessageDetailsClick = (messageId) => {
    setRedirectToDetailsMessageId(messageId); // show the details message form for a specific messageId
  } 
  
  const onGetTokenDetailsClick = (tokenId) => {
    setRedirectToDetailsTokenId(tokenId); // show the details token form for a specific tokenId
  }

  const e = props.event;
  let description = '';
  switch (e?.eventType) {
    case 'balance_topped_up':
      description = `Project balance top up`;
      break;
    case 'balance_topped_up_auto_charge':
      description = `Project balance auto recharge`;
      break;
    case 'telephone_number_released':
      description = <>Telephone number release<br /><small>{e.telephoneNumber}</small></>;
      break;
    case 'telephone_number_10dlc_purchased':
      description = <>10DLC telephone number purchase<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetPhoneNumberDetailsClick(e.telephoneNumberId)} title="Go to telephone number details">{e.telephoneNumber}</Button></>;
      break;
    case 'telephone_number_10dlc_updated':
      description = <>10DLC telephone number update<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetPhoneNumberDetailsClick(e.telephoneNumberId)} title="Go to telephone number details">{e.telephoneNumber}</Button></>;
      break;
    case 'telephone_number_toll_free_purchased':
      description = <>Toll-free telephone number purchase<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetPhoneNumberDetailsClick(e.telephoneNumberId)} title="Go to telephone number details">{e.telephoneNumber}</Button></>;
      break;
    case 'telephone_number_toll_free_updated':
      description = <>Toll-free telephone number update<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetPhoneNumberDetailsClick(e.telephoneNumberId)} title="Go to telephone number details">{e.telephoneNumber}</Button></>;
      break;
    case 'telephone_number_10dlc_renewal':
      description = <>10DLC telephone number renewal<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetPhoneNumberDetailsClick(e.telephoneNumberId)} title="Go to telephone number details">{e.telephoneNumber}</Button></>;
      break;
    case 'telephone_number_toll_free_renewal':
      description = <>Toll-free telephone number renewal<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetPhoneNumberDetailsClick(e.telephoneNumberId)} title="Go to telephone number details">{e.telephoneNumber}</Button></>;
      break;
    case 'token_created':
      description = <>Token creation<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetTokenDetailsClick(e.tokenId)} title="Go to token details">{e.tokenId}</Button></>;
      break;
    case 'token_updated':
      description = <>Token update<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetTokenDetailsClick(e.tokenId)} title="Go to token details">{e.tokenId}</Button></>;
      break;
    case 'token_deleted':
      description = <>Token delete<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetTokenDetailsClick(e.tokenId)} title="Go to token details">{e.tokenId}</Button></>;
      break;
    case 'invite_created':
      description = <>Invite sent<br /><small>{e.inviteEmail}</small></>;
      break;
    case 'invite_accepted':
      description = <>Invite accepted<br /><small>{e.inviteEmail}</small></>;
      break;
    case 'user_deleted':
      description = <>Invite accepted<br /><small>{e.userId}</small></>;
      break;
    case 'inbound_sms_completed':
      description = <>Inbound SMS<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetMessageDetailsClick(e.messageId)} title="Go to message details">{e.messageId}</Button></>;
      break;
    case 'inbound_mms_completed':
      description = <>Inbound MMS<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetMessageDetailsClick(e.messageId)} title="Go to message details">{e.messageId}</Button></>;
      break;
    case 'inbound_message_web_hook_completed':
      description = <>Inbound web hook success<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetMessageDetailsClick(e.messageId)} title="Go to message details">{e.messageId}</Button></>;
      break;
    case 'inbound_message_web_hook_failed':
      description = <>Inbound web hook failure<br /><Button style={{ padding: "0" }} color="link" className="waves-effect btn-sm" onClick={() => onGetMessageDetailsClick(e.messageId)} title="Go to message details">{e.messageId}</Button></>;
      break;
    default:
      description = '';
  }

  return (
    <React.Fragment>
      {redirectToDetailsTelephoneNumberId && <Redirect push to={`/projects/${currentProjectId}/numbers/${redirectToDetailsTelephoneNumberId}`} />}
      {redirectToDetailsMessageId && <Redirect push to={`/projects/${currentProjectId}/messages/${redirectToDetailsMessageId}`} />}
      {redirectToDetailsTokenId && <Redirect push to={`/projects/${currentProjectId}/api/tokens/${redirectToDetailsTokenId}`} />}
      <span>
        {description}
      </span>
    </React.Fragment>
  );
}