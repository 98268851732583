import React, { useEffect, useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withRouter, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";

import CreateProjectDialog from "../CreateProjectDialog";

import {
  getProjectDetails,
  onCurrentProjectIdChanged,
  getAuthUserProjects,
} from "../../store/actions";

import { userHasProjects } from "../../helpers/utils";

const ProjectSelector = () => {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.AuthUser.user);
  const projectsList = useSelector((state) => state.AuthUser.projectsList);
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [isCreateProjectDialogOpen, setIsCreateProjectDialogOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (user) {
      dispatch(getAuthUserProjects()); // have to use this way otherwise useEffect needs dependency on props and it makes it execute too often
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (currentProjectId) {
      if (window?.location?.pathname === "/dashboard") { // == we have an dashboard URL without projectId. Let's redirect.
        history.push(`/projects/${currentProjectId}/dashboard`)
      } else {
        dispatch(getProjectDetails(currentProjectId)); // this one is needed to fetch a project balance amount (located to the right on the menubar)
      }

    }
  }, [dispatch, currentProjectId, history]);


  const getSelectedProject = () => {
    if (!Array.isArray(projectsList) || projectsList.length === 0) {
      return {};
    }

    let result = projectsList.filter(p => p.projectId === currentProjectId);
    if (result.length === 0) {
      return {};
    }

    return result[0];
  }

  const getUnselectedProjects = () => {
    if (!Array.isArray(projectsList)) {
      return [];
    }

    return projectsList.filter(p => p.projectId !== currentProjectId);
  }

  const createNewProject = () => {
    setIsCreateProjectDialogOpen(true);
  }

  const hideCreateNewProjectDialog = () => {
    setIsCreateProjectDialogOpen(false);
  }

  const changeCurrentProject = (newProjectId) => {
    if (currentProjectId !== newProjectId) {
      window.location = `/projects/${newProjectId}/dashboard`; // this guarantees full page reload (we need it to avoid glitches in the header)
    }
  }

  // dropDownValue in the component is a custom property to hold projectId. It's not defined in DropdownItem.
  return (
    <React.Fragment>
      {userHasProjects(projectsList) &&
        <UncontrolledDropdown direction="down">
          <DropdownToggle tag="a" id="project-selector" className="text-reset active mm-active" caret role="button" >
            <span className="fw-semibold">Project:</span> <span className="mm-active">{getSelectedProject().name}<i className="mdi mdi-chevron-down ms-1 mm-active"></i></span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={() => createNewProject()}><span>+ Create a Project</span></DropdownItem>
            <div className="dropdown-divider" />
            {getUnselectedProjects().map((p, i) => <DropdownItem key={i} onClick={() => changeCurrentProject(p.projectId)}><span>{p.name}</span></DropdownItem>)}
          </DropdownMenu>
        </UncontrolledDropdown>
      }
      <CreateProjectDialog isOpen={isCreateProjectDialogOpen} closeDialog={hideCreateNewProjectDialog} />

    </React.Fragment>
  )
}

ProjectSelector.propTypes = {
  fetchCurrentProject: PropTypes.func,
  onCurrentProjectIdChanged: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCurrentProject: (currentProjectId) => dispatch(getProjectDetails(currentProjectId)),
    onCurrentProjectIdChanged: (currentProjectId) => dispatch(onCurrentProjectIdChanged(currentProjectId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectSelector)));