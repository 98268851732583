import {
  GET_BRANDS_FOR_PROJECT_REQUEST,
  GET_BRANDS_FOR_PROJECT_SUCCESS,
  GET_BRAND_DETAILS_REQUEST,
  GET_BRAND_DETAILS_SUCCESS,
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_SUCCESS,
  CSP_API_ERROR
} from "./actionTypes";

export const getBrandsForProject = (projectId, limit, offset) => {
  return {
    type: GET_BRANDS_FOR_PROJECT_REQUEST,
    payload: { projectId, limit, offset }
  }
};

export const getBrandsForProjectSuccess = ({ brands: brandsList, offset: brandsOffset, limit: brandsLimit, totalCount: brandsTotalCount }) => {
  return {
    type: GET_BRANDS_FOR_PROJECT_SUCCESS,
    payload: { brandsList, brandsOffset, brandsLimit, brandsTotalCount }
  }
};

export const getBrandDetails = (projectId, brandId) => {
  return {
    type: GET_BRAND_DETAILS_REQUEST,
    payload: { projectId, brandId }
  }
};

export const getBrandDetailsSuccess = brandDetails => {
  return {
    type: GET_BRAND_DETAILS_SUCCESS,
    payload: brandDetails
  }
};

export const getCampaigns = (projectId, brandId, limit, offset) => {
  return {
    type: GET_CAMPAIGNS_REQUEST,
    payload: { projectId, brandId, limit, offset }
  }
};

export const getCampaignsSuccess = ({ campaigns: campaignsList, offset: campaignsOffset, limit: campaignsLimit, totalCount: campaignsTotalCount }) => {
  return {
    type: GET_CAMPAIGNS_SUCCESS,
    payload: { campaignsList, campaignsOffset, campaignsLimit, campaignsTotalCount }
  }
};

export const apiError = error => {
  return {
    type: CSP_API_ERROR,
    payload: error,
  }
}