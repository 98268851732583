export const getMessageTypeAndDirectionString = (m) => {

  if (!m) {
    return '';
  }

  if (m.messageType === 'mms' && m.direction === 'outbound') {
    return 'Outbound MMS';
  }
  if (m.messageType === 'sms' && m.direction === 'outbound') {
    return 'Outbound SMS';
  }
  if (m.messageType === 'mms' && m.direction === 'inbound') {
    return 'Inbound MMS';
  }
  if (m.messageType === 'sms' && m.direction === 'inbound') {
    return 'Inbound SMS';
  }

  return '';
}

export const getBadgeCssClassName = (m) => {

  if (!m) {
    return 'bg-dark';
  }

  if (m.status === 'delivered' || m.status === 'received') {
    return 'bg-success';
  }

  if (m.status === 'sent') {
    return 'bg-info';
  }

  if (m.status === 'failed' || m.status === 'undelivered') {
    return 'bg-danger';
  }

  return 'bg-dark';
}
