import { all, fork } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import resetPasswordSaga from './auth/resetpwd/saga';
import LayoutSaga from './layout/saga';
import authUserSaga from './authUser/saga';
import billingSaga from './billing/saga';
import projectSaga from './project/saga';
import tokenSaga from './token/saga';
import numbersSaga from './numbers/saga';
import cspSaga from './csp/saga';
import eventSaga from './event/saga';
import messageSaga from './message/saga';
import profileSaga from './profile/saga';
import authChangePasswordSaga from './auth/changepwd/saga';

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(authUserSaga),
    fork(billingSaga),
    fork(projectSaga),
    fork(tokenSaga),
    fork(numbersSaga),
    fork(cspSaga),
    fork(eventSaga),
    fork(messageSaga),
    fork(profileSaga),
    fork(authChangePasswordSaga),
    fork(resetPasswordSaga),
  ])
}
