import { all, fork, call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  GET_BRANDS_FOR_PROJECT_REQUEST,
  GET_BRAND_DETAILS_REQUEST,
  GET_CAMPAIGNS_REQUEST,
} from "./actionTypes";
import {
  getBrandsForProjectSuccess,
  getBrandDetailsSuccess,
  getCampaignsSuccess,
  apiError
} from "./actions";

//Include Both Helper File with needed methods
import { cspService } from "../../services";

function* getBrands({ payload: {projectId, limit, offset} }) {
  try {
    const response = yield call(
      cspService.fetchBrands,
      projectId,
      limit,
      offset
    )
    yield put(getBrandsForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* getBrandDetails({ payload: { projectId, brandId } }) {
  try {
    const response = yield call(
      cspService.fetchBrandDetails,
      projectId,
      brandId
    )
    yield put(getBrandDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* getCampaigns({ payload: {projectId, brandId, limit, offset} }) {
  try {
    const response = yield call(
      cspService.fetchCampaigns,
      projectId,
      brandId,
      limit,
      offset
    )
    yield put(getCampaignsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* watchBrandsRequests() {
  yield takeEvery(GET_BRANDS_FOR_PROJECT_REQUEST, getBrands)
}

function* watchGetBrandDetailsRequests() {
  yield takeEvery(GET_BRAND_DETAILS_REQUEST, getBrandDetails)
}

function* watchCampaignsRequests() {
  yield takeEvery(GET_CAMPAIGNS_REQUEST, getCampaigns)
}

function* cspSaga() {
  yield all([
    fork(watchBrandsRequests),
    fork(watchGetBrandDetailsRequests),
    fork(watchCampaignsRequests),
  ]);
}

export default cspSaga;
