import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Alert } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getProjectDetails,
  updateInboundMessageWebhook
} from "../../store/actions";

const InboundMessageWebHook = () => {

  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const projectWebHook = useSelector((state) => state.Project.projectDetails?.project?.inboundMessageWebHook)
  const projectDetailsLoading = useSelector((state) => state.Project.loading);
  const projectAPIError = useSelector((state) => state.Project.error);

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  useEffect(() => {
    dispatch(getProjectDetails(currentProjectId)); // we don't have to call this, it creates a double HTTP request. but in the same time we cannot rely on the "magic" with projectDetails fetch after every page navigation. so let's just keep it here.
  }, [dispatch, currentProjectId]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      url: projectWebHook?.url,
      // headersHelperObject comes from Project reducer
      headerName1: projectWebHook?.headersHelperObject?.headerName1,
      headerValue1: projectWebHook?.headersHelperObject?.headerValue1,

      headerName2: projectWebHook?.headersHelperObject?.headerName2,
      headerValue2: projectWebHook?.headersHelperObject?.headerValue2,

      headerName3: projectWebHook?.headersHelperObject?.headerName3,
      headerValue3: projectWebHook?.headersHelperObject?.headerValue3,

      headerName4: projectWebHook?.headersHelperObject?.headerName4,
      headerValue4: projectWebHook?.headersHelperObject?.headerValue4,

      headerName5: projectWebHook?.headersHelperObject?.headerName5,
      headerValue5: projectWebHook?.headersHelperObject?.headerValue5,
    },
    validationSchema: Yup.object({
      url: Yup.string().max(10000, "URL is too long"),
      headerName1: Yup
        .string()
        .when("headerValue1", {
          is: (val) => val, // == not empty
          then: Yup.string().required("Enter header name")
        }),
      headerName2: Yup
        .string()
        .when("headerValue2", {
          is: (val) => val, // == not empty
          then: Yup.string().required("Enter header name")
        }),
      headerName3: Yup
        .string()
        .when("headerValue3", {
          is: (val) => val, // == not empty
          then: Yup.string().required("Enter header name")
        }),
      headerName4: Yup
        .string()
        .when("headerValue4", {
          is: (val) => val, // == not empty
          then: Yup.string().required("Enter header name")
        }),
      headerName5: Yup
        .string()
        .when("headerValue5", {
          is: (val) => val, // == not empty
          then: Yup.string().required("Enter header name")
        })
    }),
    onSubmit: async (values) => {
      console.log(values);
      let headers = {};
      for (var i = 0; i < 5; i++) {
        // constructing a map {name: value} from headerName1, headerValue1, headerName2, headerValue2 etc.
        if (values[`headerName${i + 1}`]) { // if we have a particular header value set - then add it to the input map (that is used for an update)
          headers[values[`headerName${i + 1}`]] = values[`headerValue${i + 1}`] ? values[`headerValue${i + 1}`] : null;  // allowing empty header values
        }
      }
      dispatch(updateInboundMessageWebhook(currentProjectId, values.url, headers, () => setSuccessMessage("Webhook was successfully updated")));
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Global Inbound Message Webhook" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {projectDetailsLoading && <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  }
                  {!projectDetailsLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
                  {!projectDetailsLoading && projectAPIError ? <Alert color="danger" role="alert">Something went wrong</Alert> : null}
                  {!projectDetailsLoading &&
                    <>
                      <Form
                        className="form-group top-up-balance"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Label className="form-label">Url</Label>
                          <InputGroup>
                            <Input
                              name="url"
                              placeholder="Enter Webhook URL"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.url || ""}
                              invalid={
                                validation.touched.url && validation.errors.url ? true : false
                              }
                            />
                            {validation.touched.url && validation.errors.url ? (
                              <FormFeedback type="invalid">{validation.errors.url}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <Label className="form-label">HTTP Header Name</Label>
                            <InputGroup>
                              <Input
                                name="headerName1"
                                placeholder="Enter Header Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerName1 || ""}
                                invalid={
                                  validation.errors.headerName1 ? true : false
                                }
                              />
                              {validation.errors.headerName1 ? (
                                <FormFeedback type="invalid">{validation.errors.headerName1}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col>
                            <Label className="form-label">HTTP Header Value</Label>
                            <InputGroup>
                              <Input
                                name="headerValue1"
                                placeholder="Enter Header Value"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerValue1 || ""}
                                invalid={
                                  validation.touched.headerValue1 && validation.errors.headerValue1 ? true : false
                                }
                              />
                              {validation.touched.headerValue1 && validation.errors.headerValue1 ? (
                                <FormFeedback type="invalid">{validation.errors.headerValue1}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <InputGroup>
                              <Input
                                name="headerName2"
                                placeholder="Enter Header Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerName2 || ""}
                                invalid={
                                  validation.errors.headerName2 ? true : false
                                }
                              />
                              {validation.errors.headerName2 ? (
                                <FormFeedback type="invalid">{validation.errors.headerName2}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup>
                              <Input
                                name="headerValue2"
                                placeholder="Enter Header Value"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerValue2 || ""}
                                invalid={
                                  validation.touched.headerValue2 && validation.errors.headerValue2 ? true : false
                                }
                              />
                              {validation.touched.headerValue2 && validation.errors.headerValue2 ? (
                                <FormFeedback type="invalid">{validation.errors.headerValue2}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <InputGroup>
                              <Input
                                name="headerName3"
                                placeholder="Enter Header Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerName3 || ""}
                                invalid={
                                  validation.errors.headerName3 ? true : false
                                }
                              />
                              {validation.errors.headerName3 ? (
                                <FormFeedback type="invalid">{validation.errors.headerName3}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup>
                              <Input
                                name="headerValue3"
                                placeholder="Enter Header Value"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerValue3 || ""}
                                invalid={
                                  validation.touched.headerValue3 && validation.errors.headerValue3 ? true : false
                                }
                              />
                              {validation.touched.headerValue3 && validation.errors.headerValue3 ? (
                                <FormFeedback type="invalid">{validation.errors.headerValue3}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <InputGroup>
                              <Input
                                name="headerName4"
                                placeholder="Enter Header Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerName4 || ""}
                                invalid={
                                  validation.errors.headerName4 ? true : false
                                }
                              />
                              {validation.errors.headerName4 ? (
                                <FormFeedback type="invalid">{validation.errors.headerName4}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup>
                              <Input
                                name="headerValue4"
                                placeholder="Enter Header Value"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerValue4 || ""}
                                invalid={
                                  validation.touched.headerValue4 && validation.errors.headerValue4 ? true : false
                                }
                              />
                              {validation.touched.headerValue4 && validation.errors.headerValue4 ? (
                                <FormFeedback type="invalid">{validation.errors.headerValue4}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <InputGroup>
                              <Input
                                name="headerName5"
                                placeholder="Enter Header Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerName5 || ""}
                                invalid={
                                  validation.errors.headerName5 ? true : false
                                }
                              />
                              {validation.errors.headerName5 ? (
                                <FormFeedback type="invalid">{validation.errors.headerName5}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup>
                              <Input
                                name="headerValue5"
                                placeholder="Enter Header Value"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.headerValue5 || ""}
                                invalid={
                                  validation.touched.headerValue5 && validation.errors.headerValue5 ? true : false
                                }
                              />
                              {validation.touched.headerValue5 && validation.errors.headerValue5 ? (
                                <FormFeedback type="invalid">{validation.errors.headerValue5}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

InboundMessageWebHook.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(InboundMessageWebHook)));